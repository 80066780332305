import {Col, Create, Form, Input, Row, Select, Typography, useForm, useSelect} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl, useCustomMutation, useNavigation, useTranslate} from "@pankod/refine-core";

import {IArea, IGroup, IStaff} from "interfaces";
import {fuzzySearch} from "../../utils/searchHelper";
import {toLowerCaseNonAccentVietnamese} from "../../utils/vietnameseHelper";
import {useEffect, useMemo, useState} from "react";


export const GroupCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IGroup>({
    errorNotification: {
      type: "error",
      message: t("groups.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("groups.notification.createSuccess"),
    },
  });

  const { mutate: createGroup } = useCustomMutation<IGroup>();
  const apiUrl = useApiUrl();

  const { list } = useNavigation();

  const {
    selectProps: { showSearch, onSearch, ...restSelectAreaProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "SERVER",
      },
    ],
  });

  const [areas, setAreas] = useState<any>([]);

  const {
    selectProps: {
      showSearch: showStaffSearch,
      onSearch: onStaffSearch,
      ...restSelectStaffProps
    },
    queryResult: { data: staffList },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    optionValue: "userAccess.id",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const [filterStaffList, setFilterStaffList] = useState<IStaff[]>([]);

  useEffect(() => {
    let filterStaff = staffList?.data.filter((staff) => {
      return staff.userAccess?.areas?.some(area => areas.includes(area.id));
    })
    let listTmp: IStaff[] = []
    if (filterStaff !== undefined && filterStaff.length > 0) {
      filterStaff.map((staff) => listTmp.push(staff));
    }
    setFilterStaffList(listTmp);
  }, [areas]);

  const selectStaffOptions = useMemo(() => {

    return (
      filterStaffList.map((staff) => ({
        value: staff.userAccess?.id,
        label: `${staff.fullName} (${staff.department?.name})`,
      })) ?? []
    );
  }, [filterStaffList]);

  const handleCreateGroup = async (
    props: any
  ) => {
    console.log("******* create groups *******");
    console.log(props);
    console.log(areas)
    createGroup(
      {
        url: `${apiUrl}/groups`,
        method: "post",
        values: {
          name: props?.name,
          areaIds: props?.areas,
          userAccessIds: props?.staffs,
        },
        errorNotification: {
          type: "error",
          message: t("screens.notification.createError"),
        },
        successNotification: {
          type: "success",
          message: t("screens.notification.createSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            error,
            variables,
            context,
          });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            data,
            variables,
            context,
          });
          list("groups");
        },
      }
    );
  };

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical" onFinish={(values) => handleCreateGroup(values)}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("groups.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("groups.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Khu vực được phép"
              name="areas"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.allowedAreasRequired"),
                },
              ]}
            >
              <Select
                {...restSelectAreaProps}
                value={areas}
                onChange={(v) => setAreas(v)}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Nhân viên thuộc nhóm"
              name="staffs"
              rules={[
                {
                  required: true,
                  message: "Hãy chọn nhân viên",
                },
              ]}
            >
              <Select
                {...restSelectStaffProps}
                options={selectStaffOptions}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
