import { Col, Row, Show } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import TextBox from "components/textBox";

import { IScreenSaver} from "interfaces";
import {
  FILE_STATUS_TYPE_LIST,
  ORIENTATION_TYPE_LIST,
  STORAGE_API,
  VISITOR_REGISTRATION_STATUS_LIST
} from "../../config";
import {ModalPreviewImage} from "../../components/previewImage";

export const ScreenSaverShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow<IScreenSaver>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <TextBox label={t("screensaver.fields.name")} value={record?.name} />
          </Col>

          <Col xs={24} md={12}>
            <TextBox label={t("screensaver.fields.orientation")} value={
              ORIENTATION_TYPE_LIST(t).find(
                  (item) => item.value === record?.orientation
              )?.label
            } />
          </Col>

          <Col xs={24} md={12}>
            <TextBox label={t("screensaver.fields.status")} value={
              FILE_STATUS_TYPE_LIST(t).find(
                  (item) => item.value === record?.status
              )?.label
            } />
          </Col>

          {/*<Col xs={24} md={12}>*/}
          {/*</Col>*/}

          <Col xs={24} md={12}>
            <TextBox
                label="Ảnh"
                value={record?.fileId && (
                    <ModalPreviewImage
                        url={STORAGE_API + "/libraries/public/download/" + record.fileId}
                        name="Ảnh"
                    />
                )}
            />
          </Col>

          <Col xs={24}>
            <TextBox
              label={t("screensaver.fields.description")}
              value={record?.description || ""}
              valueStyles={{
                minHeight: 88,
              }}
            />
          </Col>
        </Row>
      </Show>
    </>
  );
};
