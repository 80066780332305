import {
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
  PageHeader,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { DEVICE_TYPE_LIST } from "config";
import useUserRole from "hooks/useUserRole";

import { IArea, IDevice, IDeviceType, IStaff } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import ChangePin from "./private/ChangePin";
import { UserTable } from "./private/userTable";

export const DeviceEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { hasAdminRole } = useUserRole();
  const { Text } = Typography;
  const { formProps, saveButtonProps, formLoading, queryResult } =
    useForm<IDevice>({
      errorNotification: {
        type: "error",
        message: t("devices.notification.editError"),
      },
      successNotification: {
        type: "success",
        message: t("devices.notification.editSuccess"),
      },
    });

  const type = Form.useWatch("type", formProps?.form) as IDeviceType;
  const isAcControl = type === "ACCESS_CONTROL";

  const {
    selectProps: { onSearch, showSearch, ...restSelectProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const staff = useSelect<IStaff>({
    resource: "staffs",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const staffInfo = staff?.queryResult?.data?.data;
  const code = formProps?.initialValues?.code;

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          {hasAdminRole && <ChangePin code={queryResult?.data?.data?.code} />}
          {defaultButtons}
        </>
      )}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.typeRequired"),
                },
              ]}
            >
              <Select options={DEVICE_TYPE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.area")}
              name={["area", "id"]}
              rules={[
                {
                  required: true,
                  message: t("devices.errors.areaRequired"),
                },
              ]}
            >
              <Select
                {...restSelectProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.codeRequired"),
                },
              ]}
            >
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.ip")} name="ip">
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.mac")} name="mac">
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.firmware")} name="firmware">
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.hardware")} name="hardware">
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.kernel")} name="kernel">
              <Input disabled={isAcControl} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <></>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("devices.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col xs={24}>
        <PageHeader
          backIcon={null}
          style={{ padding: 0 }}
          title={
            <Text
              style={{
                fontSize: 16,
              }}
            >
              Danh sách user trên thiết bị
            </Text>
          }
        />
      </Col>
      <Col xs={24}>
        {staff && (<UserTable code={code} staff={staffInfo}/>)}
      </Col>
    </Edit>
  );
};
