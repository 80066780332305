import { AccessControlProvider } from "@pankod/refine-core";
import { API_RESOURCES, ROLE_BASED_RESOURCES } from "config";
import { IAccessControlActions, IUserRoleCode } from "interfaces";
import { getUserRoles } from "utils/identityHelper";

// function matchApiResources(path: string, excludedApiResources: string[]) {
//   for (const apiRes of excludedApiResources) {
//     if (path.startsWith(apiRes)) return false;
//   }
//   return true;
// }

function matchApiResources(path: string, includedApiResources: string[]) {
  for (const apiRes of includedApiResources) {
    if (path.startsWith(apiRes)) return true;
  }
  return false;
}

function hasAccessWithRole(
  role: IUserRoleCode,
  action: IAccessControlActions,
  path: string
): Promise<boolean> {
  return new Promise((resolve) => {
    // TODO: use action to check accessibility
    if (path === "dashboard") {
      resolve(true);
    } else {
      if (path.startsWith(API_RESOURCES.visitorsRegistrations)) {
        if (role === "SECURITY_DEPARTMENT" && action === "edit") {
          resolve(false);
          return;
        }

        if (role === "ADMIN" && action !== "list" && action !== "show") {
          resolve(false);
          return;
        }
      }

      if (path.startsWith(API_RESOURCES.guests)) {
        if (role === "SECURITY_DEPARTMENT" && action === "edit") {
          resolve(false);
          return;
        }

        if (role === "ADMIN" && action !== "list" && action !== "show") {
          resolve(false);
          return;
        }
      }

      if (path.startsWith(API_RESOURCES.departments)) {
        if (role !== "ADMIN" && action !== "list" && action !== "show") {
          resolve(false);
          return;
        }
      }

      resolve(matchApiResources(path, ROLE_BASED_RESOURCES[role]));
    }
  });
}

async function canAccess(
  roles: IUserRoleCode[],
  action: IAccessControlActions,
  path: string
): Promise<boolean> {
  try {
    for (const role of roles) {
      const hasAccess = await hasAccessWithRole(role, action, path);
      if (!hasAccess) return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}

export const accessControlProvider: AccessControlProvider = {
  can: async ({ resource, action, params }) => {
    const roles = getUserRoles();

    if (action === "delete" || action === "edit" || action === "show") {
      return Promise.resolve({
        can: await canAccess(roles, action, `${resource}/${params?.id}`),
      });
    }

    if (action === "field") {
      return Promise.resolve({
        can: await canAccess(roles, action, `${resource}/${params?.field}`),
      });
    }

    if (action === "list" || action === "create") {
      return Promise.resolve({
        can: await canAccess(roles, action, resource),
      });
    }

    return Promise.resolve({
      can: false,
    });
  },
};
