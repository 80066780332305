import {Col, Create, Form, Input, Row, Select, useForm, InputNumber } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { Icons } from "@pankod/refine-antd";
import { IArea } from "interfaces";
import {AREA_TYPE_LIST} from "../../config";

const {  UserOutlined  } = Icons;

export const AreaCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IArea>({
    errorNotification: {
      type: "error",
      message: t("areas.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("areas.notification.createSuccess"),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.codeRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label="Loại khu vực"
                name="type"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue="AREA"
            >
              <Select defaultValue="AREA" disabled={true} options={AREA_TYPE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={t("areas.fields.description")} name="description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
