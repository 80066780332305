import {
  // BooleanField,
  Col,
  Form,
  Icons,
  Input,
  List,
  Row,
  Table,
  Tag,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError, useTranslate } from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import { API_RESOURCES, DEVICE_TYPE_LIST } from "config";

import { IArea, IDevice } from "interfaces";
import {SpecialAreaList} from "../list";

const {
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  // CloseCircleTwoTone, CheckCircleTwoTone
} = Icons;

interface IResourceDeviceListProps {
  area?: IArea;
}

export const SpecialAreaDeviceList: React.FC<IResourceDeviceListProps> = ({
  area,
}) => {
  const t = useTranslate();
  const areaId = area?.id;

  // const permanentFilter: CrudFilters = areaId
  //   ? [{ field: "areaId", value: areaId, operator: "eq" }]
  //   : [];

  const { tableProps, searchFormProps } = useTable<
    IDevice,
    HttpError,
    { q: string }
  >({
    resource: `${API_RESOURCES.devices}/find_by_area/${areaId}`,
    // permanentFilter: permanentFilter,
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchDevice } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      canCreate={false}
      resource={API_RESOURCES.devices}
      breadcrumb={null}
      headerProps={{
        style: {
          padding: `50px 0px`,
        },
      }}
    >
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDevice()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col />
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("devices.fields.name")}
        />

        <Table.Column
          dataIndex="code"
          key="code"
          title={t("devices.fields.code")}
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title={t("devices.fields.type")}
          render={(type) => (
            <TextField
              value={
                DEVICE_TYPE_LIST(t).find(
                  (deviceType) => deviceType.value === type
                )?.label
              }
            />
          )}
        />

        <Table.Column
          dataIndex="area"
          key="area"
          title={t("devices.fields.area")}
          render={(area) => <TextField value={area?.name} />}
        />

        {/* <Table.Column<IDevice>
          dataIndex="status"
          key="status"
          title={t("devices.fields.status")}
          render={(status) => (
            <BooleanField
              value={status}
              valueLabelFalse={t("devices.fields.status_false")}
              valueLabelTrue={t("devices.fields.status_true")}
              falseIcon={<CloseCircleTwoTone twoToneColor="#E60019" />}
              trueIcon={<CheckCircleTwoTone />}
            />
          )}
        /> */}

        <Table.Column<IDevice>
          dataIndex="status"
          key="status"
          title={t("devices.fields.status")}
          render={(status) =>
            status ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("devices.fields.status_true")}
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {t("devices.fields.status_false")}
              </Tag>
            )
          }
        />
      </Table>
    </List>
  );
};
