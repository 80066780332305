import {List, Table} from "@pankod/refine-antd";
import {useTranslate} from "@pankod/refine-core";
import {useState} from "react";
import {IArea, IAreaUser, IStaff} from "interfaces";


interface UserTableProps {
  staffList: any;
  areaList: any;
  areas: any;
  areaUserList: any;
}

export const AreaUserTable: React.FC<UserTableProps> = ({ staffList, areaList, areas, areaUserList}) => {
  const t = useTranslate();
  const [isRefetching, setIsRefetching] = useState(false);
  let areaUserTmp: IAreaUser[] = [];
  areaUserList?.data?.map((a: any) => areaUserTmp.push(a));

  let staffTmp: IStaff[] = [];
  staffList.map((s: any) => staffTmp.push(s));
  let areaTmp: IArea[] = [];
  areas?.map((a: any) => areaTmp.push(a));
  const dataSet = areaUserTmp.filter((au) => areas?.filter((a: any) => a?.staffs !== undefined && a?.staffs.length > 0).map((a: IArea) => a?.id).includes(au?.areaId)).map((item: any, index: number) => ({
      ...item,
      key: index + 1,
      staff: `${staffTmp.filter((s) => s?.id === item?.staffId)[0]?.fullName} - ${staffTmp.filter((s) => s?.id === item?.staffId)[0]?.department?.name}`,
      area: areaTmp.filter((a) => a?.id === item?.areaId)[0]?.name,
    }));

  return (
    <>
      {
        !isRefetching && (
          <List
            breadcrumb={null}
            // headerButtons={
            //   <AddAreaUser areaList={areas} />
            // }
            headerButtons={null}
            canCreate={false}
          >
            <Table rowKey="key" dataSource={dataSet}>
              <Table.Column dataIndex="key" key="key" title={"#"} />

              <Table.Column
                dataIndex="staff"
                key="staff"
                title={t("staffs.fields.name")}
              />

              <Table.Column
                dataIndex="area"
                key="area"
                title="Khu vực"
              />

              {/*<Table.Column<any>*/}
              {/*  dataIndex="actions"*/}
              {/*  title="Hành động"*/}
              {/*  render={(_, record) => {*/}
              {/*    console.log("********* area user record ***********");*/}
              {/*    console.log(record)*/}
              {/*    return (*/}
              {/*      <Space>*/}
              {/*        <DeleteButton*/}
              {/*          hideText*/}
              {/*          size="small"*/}
              {/*          resourceName="area-user"*/}
              {/*          recordItemId={record.areaUserId}*/}
              {/*          errorNotification={{*/}
              {/*            type: "error",*/}
              {/*            message: t("areas.notification.deleteError"),*/}
              {/*          }}*/}
              {/*          successNotification={{*/}
              {/*            type: "success",*/}
              {/*            message: t("areas.notification.deleteSuccess"),*/}
              {/*          }}*/}
              {/*          onSuccess={()=>window.location.reload()}*/}
              {/*        />*/}
              {/*      </Space>*/}
              {/*    )}}*/}
              {/*/>*/}
            </Table>
          </List>
        )
      }
    </>
  );
};