import {
  Button, Col,
  Form,
  Icons,
  Input,
  Modal, Select,
  useModal, useSelect,
} from "@pankod/refine-antd";
import {useApiUrl, useCustomMutation, useNavigation} from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import {IArea, IDevice, IStaff, IUpdateAreaUserTypes, IUpdateDevicePinTypes} from "interfaces";
import {useEffect, useMemo, useState} from "react";
import {fuzzySearch} from "../../../utils/searchHelper";
import {toLowerCaseNonAccentVietnamese} from "../../../utils/vietnameseHelper";

const { PlusOutlined } = Icons;

type ChangePinProps = { area: IArea};

const AddAreaUser2: React.FC<ChangePinProps> = ({ area }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const apiUrl = useApiUrl();
  const { mutate: updateInfo, isLoading } =
    useCustomMutation<IUpdateAreaUserTypes>();

  // const {
  //   selectProps: { showSearch, onSearch, ...restSelectProps },
  // } = useSelect<IArea>({
  //   resource: "areas",
  //   optionLabel: "name",
  //   pagination: {
  //     current: 1,
  //     pageSize: 99999, // use the large PageSize to get full
  //   },
  //   filters: [
  //     {
  //       field: "type",
  //       operator: "eq",
  //       value: "SERVER",
  //     },
  //   ],
  // });

  console.log("******** area tmp ********")
  console.log(area)

  const selectAreaOptions = useMemo(() => {
    return (
      [{
        value: area?.id,
        label: area?.name,
      }]
    );
  }, [area]);

  const [areas, setAreas] = useState<any>([]);

  const {
    selectProps: {
      showSearch: showStaffSearch,
      onSearch: onStaffSearch,
      ...restSelectStaffProps
    },
    queryResult: { data: staffList },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const [filterStaffList, setFilterStaffList] = useState<IStaff[]>([]);

  useEffect(() => {
    let filterStaff = staffList?.data.filter((staff) => {
      return staff.userAccess?.areas?.some(area => areas.includes(area.id));
    })
    let listTmp: IStaff[] = []
    if (filterStaff !== undefined && filterStaff.length > 0) {
      filterStaff.map((staff) => listTmp.push(staff));
    }
    setFilterStaffList(listTmp);
  }, [areas]);

  const selectStaffOptions = useMemo(() => {

    return (
      filterStaffList.map((staff) => ({
        value: staff?.id,
        label: `${staff.fullName} (${staff.department?.name})`,
      })) ?? []
    );
  }, [filterStaffList]);
  const { list } = useNavigation();
  const handleAdd = async (props: any) => {
    console.log("****** create ****")
    console.log(props);
    updateInfo(
      {
        url: `${apiUrl}/area-user/create`,
        method: "post",
        values: {
          areaId: props?.area,
          staffId: props?.staff?.id
        },
        errorNotification(error: any) {
          return {
            type: "error",
            message: "Create Error"
          };
        },
        successNotification: {
          type: "success",
          message: "Create Success",
        },
      },
      {
        onSuccess() {
          close();
          window.location.reload();
        },
      }
    );
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: "Thêm nhân viên bắt buộc",
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        loading: isLoading,
      },
      onOk: () => {
        form
          .validateFields()
          .then((values) => handleAdd(values))
          .catch((error) => console.log("error", error));
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => show()}>
        Thêm nhân viên bắt buộc
      </Button>

      <Modal {...modalProps}>
        <Form layout="vertical" form={form}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.area")}
              name={"area"}
              rules={[
                {
                  required: true,
                  message: t("devices.errors.areaRequired"),
                },
              ]}
            >
              <Select
                options={selectAreaOptions}
                value={areas}
                onChange={(v) => setAreas(v)}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Người nhân viên"
              name={["staff", "id"]}
              rules={[
                {
                  required: true,
                  message: "Yêu cầu chọn nhân viên",
                },
              ]}
            >
              <Select
                {...restSelectStaffProps}
                options={selectStaffOptions}
                showSearch={true}
                // mode="single"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(
                      String(option?.label ?? "")
                    ),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default AddAreaUser2;
