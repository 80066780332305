import {
  ErrorComponent,
  notificationProvider,
  ReadyPage,
} from "@pankod/refine-antd";
import { Authenticated, Refine } from "@pankod/refine-core";

import { Icons } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";

// style
import "styles/antd.less";

// pages
// import { AccountInfoPage } from "pages/account";
import DashboardPage from "pages/dashboard";
import { ForgotPasswordPage, LoginPage, UpdatePasswordPage } from "pages/auth";
// import { PostCreate, PostEdit, PostList, PostShow } from "pages/posts";
import { AreaCreate, AreaEdit, AreaList, AreaShow } from "pages/areas";
import {
  DepartmentCreate,
  DepartmentEdit,
  DepartmentList,
  DepartmentShow,
} from "pages/departments";
import { DeviceCreate, DeviceEdit, DeviceList } from "pages/devices";
import { StaffCreate, StaffEdit, StaffList, StaffShow } from "pages/staffs";
import { UserCreate, UserEdit, UserList } from "pages/users";
import { RfidCreate, RfidEdit, RfidList } from "pages/rfids";
import { ElevatorCreate, ElevatorEdit, ElevatorList } from "pages/elevators";
import { VehicleCreate, VehicleEdit, VehicleList } from "pages/vehicles";
import {
  VisitorRegistrationCreate,
  VisitorRegistrationEdit,
  VisitorRegistrationList,
  VisitorRegistrationShow,
} from "pages/visitorsRegistrations";

import {GuestCreate, GuestEdit, GuestList, GuestShow} from "pages/guests";

// components
import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider,
  Title,
} from "components/layout";

// provider
import { accessControlProvider, authProvider, dataProvider } from "./providers";

// config
import { API_RESOURCES, HOST_API, PATHS } from "config";

// axios
import axiosIntance from "./axiosInstance";
import { DeviceLogList } from "pages/deviceLogs";
import { TimeKeepingList } from "pages/timekeeping";
import {ScreenSaverCreate, ScreenSaverList, ScreenSaverShow} from "./pages/screenSaver";
import {ScreenSaverEdit} from "./pages/screenSaver";
import {AreaUserList} from "./pages/areaUser";
import {AreaUserCreate} from "./pages/areaUser/create";
import {ScreenList} from "./pages/screens";
import {ScreenCreate} from "./pages/screens/create";
import {ScreenEdit} from "./pages/screens/edit";
import {GroupList} from "./pages/groups";
import {GroupCreate} from "./pages/groups/create";
import {GroupEdit} from "./pages/groups/edit";
import {SpecialAreaCreate, SpecialAreaEdit, SpecialAreaList} from "./pages/specialAreas";
import {SpecicalAreaShow} from "./pages/specialAreas/show";

// mock
// import "mock";

// ------------------------------------------------------------------------

const {
  UserOutlined,
  DesktopOutlined,
  SafetyOutlined,
  LayoutOutlined,
  SolutionOutlined,
  FormOutlined,
  WifiOutlined,
  CarOutlined,
  BugOutlined,
  GroupOutlined,
  FieldTimeOutlined,
  FundProjectionScreenOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} = Icons;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      DashboardPage={DashboardPage}
      catchAll={<ErrorComponent />}
      dataProvider={dataProvider(HOST_API, axiosIntance)}
      authProvider={authProvider}
      accessControlProvider={accessControlProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            path: PATHS.forgotPassword,
            element: <ForgotPasswordPage />,
          },
          {
            path: PATHS.updatePassword,
            element: (
              <Authenticated fallback={<LoginPage />}>
                <UpdatePasswordPage />
              </Authenticated>
            ),
          },
          // {
          //   path: PATHS.accountMe,
          //   element: (
          //     <Authenticated>
          //       <AccountInfoPage />
          //     </Authenticated>
          //   ),
          // },
        ],
      }}
      resources={[
        // {
        //   name: "posts",
        //   list: PostList,
        //   create: PostCreate,
        //   edit: PostEdit,
        //   show: PostShow,
        // },
        // {
        //   name: "accounts",
        //   icon: <UserOutlined />,
        // },
        {
          name: API_RESOURCES.users,
          icon: <UserOutlined />,
          // parentName: "accounts",
          list: UserList,
          edit: UserEdit,
          create: UserCreate,
        },
        {
          name: API_RESOURCES.staffs,
          icon: <SolutionOutlined />,
          list: StaffList,
          edit: StaffEdit,
          create: StaffCreate,
          show: StaffShow,
        },
        {
          name: API_RESOURCES.departments,
          icon: <LayoutOutlined />,
          list: DepartmentList,
          edit: DepartmentEdit,
          create: DepartmentCreate,
          show: DepartmentShow,
        },
        {
          name: API_RESOURCES.areas,
          icon: <SafetyOutlined />,
          list: AreaList,
          edit: AreaEdit,
          create: AreaCreate,
          show: AreaShow,
        },
        {
          name: API_RESOURCES.devices,
          icon: <DesktopOutlined />,
          list: DeviceList,
          edit: DeviceEdit,
          create: DeviceCreate,
        },
        {
          name: API_RESOURCES.rfids,
          icon: <WifiOutlined />,
          list: RfidList,
          edit: RfidEdit,
          create: RfidCreate,
        },
        {
          name: API_RESOURCES.elevators,
          icon: <GroupOutlined />,
          list: ElevatorList,
          edit: ElevatorEdit,
          create: ElevatorCreate,
        },
        {
          name: API_RESOURCES.vehicles,
          icon: <CarOutlined />,
          list: VehicleList,
          edit: VehicleEdit,
          create: VehicleCreate,
        },
        // {
        //   name: API_RESOURCES.visitorsRegistrations,
        //   icon: <FormOutlined />,
        //   list: VisitorRegistrationList,
        //   edit: VisitorRegistrationEdit,
        //   create: VisitorRegistrationCreate,
        //   show: VisitorRegistrationShow,
        // },
        {
          name: API_RESOURCES.guests,
          icon: <FormOutlined />,
          list: GuestList,
          edit: GuestEdit,
          create: GuestCreate,
          show: GuestShow,
        },
        {
          name: API_RESOURCES.multiAuthen,
          list: ScreenList
        },
        {
          name: API_RESOURCES.screensaver,
          icon: <FundProjectionScreenOutlined />,
          list: ScreenSaverList,
          edit: ScreenSaverEdit,
          create: ScreenSaverCreate,
          show: ScreenSaverShow,
          parentName: API_RESOURCES.multiAuthen,
        },
        // {
        //   name: API_RESOURCES.areaUser,
        //   icon: <UserSwitchOutlined />,
        //   list: AreaUserList,
        //   create: AreaUserCreate,
        //   parentName: API_RESOURCES.multiAuthen,
        // },
        {
          name: API_RESOURCES.specialArea,
          icon: <UserSwitchOutlined />,
          list: SpecialAreaList,
          create: SpecialAreaCreate,
          edit: SpecialAreaEdit,
          show: SpecicalAreaShow,
          parentName: API_RESOURCES.multiAuthen,
        },
        {
          name: API_RESOURCES.screens,
          icon: <FundProjectionScreenOutlined />,
          list: ScreenList,
          create: ScreenCreate,
          edit: ScreenEdit,
          parentName: API_RESOURCES.multiAuthen,
        },
        {
          name: API_RESOURCES.groups,
          icon: <UsergroupAddOutlined />,
          list: GroupList,
          create: GroupCreate,
          edit: GroupEdit,
          parentName: API_RESOURCES.multiAuthen,
        },
        {
          name: API_RESOURCES.deviceLogs,
          icon: <BugOutlined />,
          list: DeviceLogList,
        },
        {
          name: API_RESOURCES.timekeeping,
          icon: <FieldTimeOutlined />,
          list: TimeKeepingList,
        },
      ]}
      LoginPage={LoginPage}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
      options={{
        syncWithLocation: true,
        reactQuery: {
          devtoolConfig: {
            initialIsOpen: false,
            position: "bottom-right",
          },
        },
      }}
    />
  );
}

export default App;
