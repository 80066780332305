import {
    Button,
    Checkbox,
    Col,
    Form,
    Icons,
    Modal, notification,
    Row,
    Typography,
    useModal,
} from "@pankod/refine-antd";
import {BaseRecord, useApiUrl, useCustom, useCustomMutation} from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { IDevice, IResetDeviceTypes } from "interfaces";
import TextBox from "components/textBox";
import axiosInstance from "../../../axiosInstance";
import {TIME_FORMAT, TIMEKEEPING_STATUS} from "../../../config";
import dayjs from "dayjs";
import {exportToCSV} from "../../../utils/commonHelper";

const { FileSyncOutlined } = Icons;

interface DeviceProps {
    data: IDevice;
}

const DeviceSyncData: React.FC<DeviceProps> = ({ data }) => {
    const { t } = useTranslation();
    const apiUrl = useApiUrl();

    const handleSyncDevice = async () => {
        if (!data?.code) return;
        try {
           await axiosInstance.post(`${apiUrl}/devices/re-sync/${data?.code}`);
            notification.success({
                message: "Đồng bộ thành công",
            });
           close();
        } catch (error: any) {
            notification.error({
                message: "Đồng bộ thất bại",
            });
        }
    };

    const { modalProps, show, close } = useModal({
        modalProps: {
            title: "Đồng bộ data thiết bị",
            okText: t("buttons.ok"),
            cancelText: t("buttons.cancel"),
            afterClose() {
            },
            okButtonProps: {
                disabled: false,
            },
            onOk: () => {
               handleSyncDevice()
                    .catch((error) => console.log("error", error));
            },
            onCancel: () => {
            },
        },
    });

    return (
        <>
            <Button icon={<FileSyncOutlined />} size="small" onClick={() => show()} />

            <Modal {...modalProps}>
                <Row gutter={[16, 24]}>
                    <Col xs={24}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            {t("devices.reset_device.common")}
                        </Typography.Title>
                    </Col>
                    <Col xs={12}>
                        <TextBox label={t("devices.fields.name")} value={data?.name} />
                    </Col>
                    <Col xs={12}>
                        <TextBox label={t("devices.fields.code")} value={data?.code} />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default DeviceSyncData;
