import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  FilterDropdown,
  Form,
  Icons,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  notification,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  LogicalFilter,
  getDefaultFilter,
  useApiUrl,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { RangePicker } from "components/datepicker";
import {
  DATE_TIME_FORMAT,
  DEVICE_LOG_EVENT_TYPES,
  FULL_DATE_TIME_FORMAT,
  USER_ACCESS_TYPES,
} from "config";
import { IArea, IDevice, IDeviceLog, IStaff } from "interfaces";
import { downloadData } from "utils/commonHelper";
import axiosInstance from "axiosInstance";

const { SearchOutlined, ExportOutlined } = Icons;

export const DeviceLogList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [rangeDate, setRangeDate] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | undefined
  >();

  const { tableProps, searchFormProps, filters, setFilters } = useTable<
    IDeviceLog,
    HttpError,
    { q: string }
  >({
    syncWithLocation: true,
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  useEffect(() => {
    const filterEventFrom = getDefaultFilter("eventFrom", filters, "eq");
    const filterEventTo = getDefaultFilter("eventTo", filters, "eq");
    setRangeDate([
      filterEventFrom ? dayjs(filterEventFrom) : null,
      filterEventTo ? dayjs(filterEventTo) : null,
    ]);
  }, [filters]);

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchDevice } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IDevice>();

  const eventTypeOptions = useMemo(
    () =>
      DEVICE_LOG_EVENT_TYPES.map((eventType) => ({
        value: eventType,
        label: t(`device_logs.event_types.${eventType}`),
      })),
    [t]
  );

  const userAccessTypeOptions = useMemo(
    () =>
      USER_ACCESS_TYPES.map((userAccessType) => ({
        value: userAccessType,
        label: t(
          `device_logs.fields.userType_${userAccessType}`,
          userAccessType
        ),
      })),
    [t]
  );

  const {
    selectProps: {
      showSearch: showSearchDevice,
      onSearch: onSearchDevice,
      ...restSelectDeviceProps
    },
  } = useSelect<IDevice>({
    resource: "devices",
    optionLabel: "code",
    optionValue: "code",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const deviceOptions = useMemo(
    () => restSelectDeviceProps.options,
    [restSelectDeviceProps]
  );

  const { queryResult: queryResultAccessCode } = useSelect({
    resource: "device_logs/access_codes",
    hasPagination: false,
  });

  const accessCodeOptions = useMemo(
    () =>
      queryResultAccessCode?.data?.data.map((it) => ({
        value: it,
        label: it,
      })),
    [queryResultAccessCode]
  );

  const {
    selectProps: {
      showSearch: showSearchArea,
      onSearch: onSearchArea,
      ...restSelectAreaProps
    },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const areaOptions = useMemo(
    () => restSelectAreaProps.options,
    [restSelectAreaProps]
  );

  const apiUrl = useApiUrl();
  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const refetchExport = async () => {
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(`${apiUrl}/device_logs/export`, {
        responseType: "blob",
        params: {
          eventType: getDefaultFilter("eventType", filters, "eq"),
          eventFrom: getDefaultFilter("eventFrom", filters, "eq"),
          eventTo: getDefaultFilter("eventTo", filters, "eq"),
          deviceCode: getDefaultFilter("deviceCode", filters, "eq"),
          accessCode: getDefaultFilter("accessCode", filters, "eq"),
          areaIds: getDefaultFilter("areaIds", filters, "in"),
          userAccessType: getDefaultFilter("userAccessType", filters, "eq"),
        },
        // paramsSerializer(params) {
        //   return Object.entries(params)
        //     .map(([key, value]) => {
        //       if (Array.isArray(value)) {
        //         return value.map((val) => `${key}=${val}`).join("&");
        //       }
        //       return `${key}=${value}`;
        //     })
        //     .join("&");
        // },
      });
      downloadData(data, t("device_logs.filename"));
      notification.success({
        message: t("device_logs.notification.exportSuccess"),
      });
    } catch (error: any) {
      notification.error({
        message: error?.message ?? t("device_logs.notification.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  const {
    selectProps: { showSearch, ...restSelectStaffProps },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    optionValue: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const staffOptions = useMemo(
    () => restSelectStaffProps.options,
    [restSelectStaffProps]
  );

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDevice()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<ExportOutlined />}
            onClick={refetchExport}
            loading={isRefetchingExport}
            disabled={isRefetchingExport}
          >
            {t("buttons.export")}
          </Button>
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="deviceCode"
          key="deviceCode"
          title={t("device_logs.fields.deviceCode")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("device_logs.fields.deviceCode")}
                options={deviceOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex={["area", "name"]}
          key="areaIds"
          title={t("device_logs.fields.area")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="multiple"
                style={{ minWidth: 250 }}
                placeholder={t("device_logs.fields.area")}
                options={areaOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="timestamp"
          key="timestamp"
          title={t("device_logs.fields.timestamp")}
          render={(exp) => dayjs(exp).format(FULL_DATE_TIME_FORMAT)}
          filterDropdown={(props) => (
            <div
              style={{
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 16,
              }}
            >
              <RangePicker
                format={DATE_TIME_FORMAT}
                showTime
                showSecond={false}
                value={rangeDate}
                onCalendarChange={(rangeValue) => {
                  setRangeDate(rangeValue as [dayjs.Dayjs, dayjs.Dayjs]);
                }}
              />
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setFilters((filters) => {
                      const finalFilters: CrudFilters = filters
                        .filter((filter) => {
                          const lFilter = filter as LogicalFilter;
                          return (
                            lFilter.field !== "eventFrom" &&
                            lFilter.field !== "eventTo"
                          );
                        })
                        .concat({
                          field: "eventFrom",
                          operator: "eq",
                          value: rangeDate?.[0]?.toISOString(),
                        })
                        .concat({
                          field: "eventTo",
                          operator: "eq",
                          value: rangeDate?.[1]?.toISOString(),
                        });
                      return finalFilters;
                    });
                    props.confirm({ closeDropdown: true });
                  }}
                >
                  <FilterOutlined /> {t("buttons.filter")}
                </Button>
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    setFilters((filters) => {
                      const finalFilters: CrudFilters = filters.filter(
                        (filter) => {
                          const lFilter = filter as LogicalFilter;
                          return (
                            lFilter.field !== "eventFrom" &&
                            lFilter.field !== "eventTo"
                          );
                        }
                      );
                      return finalFilters;
                    });
                    props.confirm({ closeDropdown: true });
                  }}
                >
                  {t("buttons.clear")}
                </Button>
              </Space>
            </div>
          )}
        />

        <Table.Column
          dataIndex="type"
          key="eventType"
          title={t("device_logs.fields.type")}
          render={(eventType) =>
            t(`device_logs.event_types.${eventType}`, eventType)
          }
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                // mode="multiple"
                placeholder={t("device_logs.fields.type")}
                options={eventTypeOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex={["userAccess", "fullName"]}
          key="fullName"
          title={t("device_logs.fields.fullName")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder="Họ và tên"
                options={staffOptions}
                showSearch={true}
                filterOption={(inputValue, option: any) => {
                  console.log(option)
                  return option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                }
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex={["userAccess", "userType"]}
          key="userAccessType"
          title={t("device_logs.fields.userType")}
          render={(userType) =>
            userType
              ? t(`device_logs.fields.userType_${userType}`, userType)
              : ""
          }
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("device_logs.fields.userType")}
                options={userAccessTypeOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="accessCode"
          key="accessCode"
          title={t("device_logs.fields.accessCode")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("device_logs.fields.accessCode")}
                options={accessCodeOptions}
              />
            </FilterDropdown>
          )}
        />
      </Table>
    </List>
  );
};
