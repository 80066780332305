import {
  Button,
  Checkbox,
  Col,
  Form,
  Icons,
  Modal,
  Row, Select,
  Typography,
  useModal,
} from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { IDevice, IResetDeviceTypes } from "interfaces";
import TextBox from "components/textBox";
import {AREA_TYPE_LIST, AUTHEN_MODE_TYPE_LIST} from "../../../config";

const { UsergroupAddOutlined } = Icons;

interface ResetDeviceProps {
  data: IDevice;
}

const SetDeviceMode: React.FC<ResetDeviceProps> = ({ data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const apiUrl = useApiUrl();
  const { mutate: setDeviceMode, isLoading } =
    useCustomMutation<any>();

  const handleSetDeviceMode = async (values: any) => {
    if (!data?.code) return;

    console.log("********* handleSetDeviceMode **********8")
    console.log(values);

    setDeviceMode(
      {
        url: `${apiUrl}/devices/set-auth`,
        method: "post",
        values: {
          deviceCodes: [data?.code],
          authMode: values?.authenMode,
        },
        errorNotification(error: any) {
          return {
            type: "error",
            message:
              error?.message ??
              "Thiết lập thất bại",
          };
        },
        successNotification: {
          type: "success",
          message: "Thiết lập thành công",
        },
      },
      {
        onSuccess() {
          close();
        },
      }
    );
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: "Thiết lập mode cho thiết bị",
      okText: t("buttons.ok"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        // loading: isLoading,
        // disabled: !resetNetwork && !resetUsers && !resetDB,
      },
      onOk: () => {
        form
          .validateFields()
          .then((values) => handleSetDeviceMode(values))
          .catch((error) => console.log("error", error));
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });

  return (
    <>
      <Button icon={<UsergroupAddOutlined />} size="small" onClick={() => show()} />

      <Modal {...modalProps}>
        <Row gutter={[16, 24]}>
          <Col xs={24}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("devices.reset_device.common")}
            </Typography.Title>
          </Col>
          <Col xs={12}>
            <TextBox label={t("devices.fields.name")} value={data?.name} />
          </Col>
          <Col xs={12}>
            <TextBox label={t("devices.fields.code")} value={data?.code} />
          </Col>

          <Col xs={24}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {"Chọn mode cho thiết bị"}
            </Typography.Title>
          </Col>
          <Col xs={24}>
            <Form layout="vertical" form={form}>
              <Form.Item
                  label="Chế độ authen"
                  name="authenMode"
                  rules={[
                    {
                      required: true,
                      message: "Chọn chế độ authen"
                    },
                  ]}
              >
                <Select mode="multiple" options={AUTHEN_MODE_TYPE_LIST(t)} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SetDeviceMode;
