import {
  CreateButton,
  DeleteButton,
  EditButton,
  Icons,
  List,
  ShowButton,
  Space,
  Table, TextField, useSelect,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";

import {IDevice, IGroup, IScreen, IScreenSaver, IStaff} from "interfaces";
import {useMemo} from "react";

const {
  // SearchOutlined,
  PlusOutlined,
} = Icons;

export const GroupList: React.FC<IResourceComponentsProps> = ({ canCreate }) => {
  const t = useTranslate();

  const {
    tableProps,
    // searchFormProps
  } = useTable<IGroup, HttpError, { q: string }>({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  // const {
  //   selectProps: { onSearch, showSearch, ...restSelectProps },
  //   queryResult,
  // } = useSelect<IDevice>({
  //   resource: "devices",
  //   optionLabel: "name",
  //   pagination: {
  //     current: 1,
  //     pageSize: 99999, // use the large PageSize to get full
  //   },
  // });
  //
  // const deviceOptions = useMemo(
  //   () => restSelectProps.options,
  //   [restSelectProps]
  // );

  return (
    <List
      headerButtons={
        canCreate ? (
          <CreateButton type="primary" icon={<PlusOutlined />}>
            {t("buttons.create_new")}
          </CreateButton>
        ) : null
      }
    >
      {/* <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searhArea()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row> */}

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("groups.fields.name")}
        />

        {/*<Table.Column*/}
        {/*  dataIndex="ip"*/}
        {/*  key="ip"*/}
        {/*  title={t("screens.fields.ip")}*/}
        {/*  // render={(orientation) => <TextField value={orientation === "HORIZONTAL" ? "Nằm ngang": "Nằm dọc"} />}*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*    dataIndex="serialNumber"*/}
        {/*    key="serialNumber"*/}
        {/*    title={t("screens.fields.serialNumber")}*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*    dataIndex="androidId"*/}
        {/*    key="androidId"*/}
        {/*    title={t("screens.fields.androidId")}*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*  dataIndex="deviceId"*/}
        {/*  key="deviceId"*/}
        {/*  title="Thiết bị access control"*/}
        {/*  render={(value) => {*/}
        {/*    return (*/}
        {/*      <TextField*/}
        {/*        value={*/}
        {/*          deviceOptions?.find((item) => item.value === value)?.label*/}
        {/*        }*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*  dataIndex="screenMode"*/}
        {/*  key="screenMode"*/}
        {/*  title={t("screens.fields.screenMode")}*/}
        {/*  render={(orientation) => <TextField value={orientation === "HORIZONTAL" ? "Nằm ngang": "Nằm dọc"} />}*/}
        {/*/>*/}

        {/* <Table.Column<IArea>
          dataIndex="area-status"
          key="area-status"
          title={t("areas.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.areas,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("areas.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("areas.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        <Table.Column<IScreen>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/*<ShowButton hideText size="small" recordItemId={record.id} />*/}
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("groups.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("groups.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
