import {
  Button,
  Form,
  Icons,
  Modal,
  notification,
  useModal,
} from "@pankod/refine-antd";
import { useApiUrl } from "@pankod/refine-core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import axiosInstance from "axiosInstance";
import { generateFilter, exportToCSV } from "utils/commonHelper";
import { TIMEKEEPING_STATUS, TIME_FORMAT } from "config";
import dayjs from "dayjs";
const { ExportOutlined } = Icons;

interface ExportProps {
  data: any
}

export const TimekeepingExport: React.FC<ExportProps> = ({data}) => {
  let filter = generateFilter(data);
  // console.log(filter);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const apiUrl = useApiUrl();
  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const handleExportAttendance = async () => {
    const page: number = 0;
    const size: number = 99999;
    const onTimeValue = filter.onTime ? filter.onTime: "Chưa xác định";
    const departmentValue = filter.department ? filter.department: null;
    const startTimeValue = filter.startTime ? filter.startTime: null;
    const endTimeValue = filter.endTime ? filter.endTime: null;
    const qValue = filter.q ? filter.q : null;
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(`${apiUrl}/timekeeping`, {
        params: {
          page,
          size,
          department: departmentValue,
          onTime: onTimeValue,
          startTime: startTimeValue,
          endTime: endTimeValue,
          q: qValue,
        },
      });
      // console.log(data.content);
      let ret = data.content.map((item: any, index: number) => ({
        ...item,
        id: index + 1,
        date: item.date,
        name: item.name,
        department: item.department.name,
        onTime: TIMEKEEPING_STATUS().find(
          (status) => status.value === item.onTime
        )?.label,
        startTime: item.startTime != null ? dayjs(item.startTime).format(TIME_FORMAT): "Chưa xác định",
        endTime: item.endTime != null ? dayjs(item.endTime).format(TIME_FORMAT): "Chưa xác định",
        userType: item.userType === "STAFF" ? "Nhân viên": "Khách",
        mobile: null,
      }));

      exportToCSV(ret, "File chấm công " + (new Date()).toLocaleString());
      notification.success({
        message: t("staffs.attendance_exports.notification.exportSuccess"),
      });
      close();
    } catch (error: any) {
      notification.error({
        message:
          error?.message ??
          t("staffs.attendance_exports.notification.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: t("staffs.attendance_exports.title"),
      okText: t("buttons.ok"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        loading: isRefetchingExport,
      },
      onOk: () => {
        handleExportAttendance();
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });


  return (
    <>
      <Button icon={<ExportOutlined />} onClick={() => show()}>
        {t("staffs.attendance_exports.button")}
      </Button>

      <Modal {...modalProps}>
      </Modal>
    </>
  );
};
