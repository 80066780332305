import {Col, Create, Form, Input, Row, Select, Typography, useForm, useSelect} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl, useCustomMutation, useNavigation, useTranslate} from "@pankod/refine-core";

import {IScreen} from "interfaces";


export const ScreenCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IScreen>({
    errorNotification: {
      type: "error",
      message: t("screens.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("screens.notification.createSuccess"),
    },
  });

  const { mutate: updateScreenSaverFile } = useCustomMutation<IScreen>();
  const apiUrl = useApiUrl();

  const { list } = useNavigation();

  const handleCreateScreen = async (
      props: any
  ) => {
    console.log("******* create screense *******");
    console.log(props);
    updateScreenSaverFile(
        {
          url: `${apiUrl}/screens`,
          method: "post",
          values: {
            name: props?.name,
            ip: props?.ip,
            serialNumber: props?.serialNumber,
            rfidTopic: props?.rfidTopic,
            type: props?.type,
            androidId: props?.androidId,
          },
          errorNotification: {
            type: "error",
            message: t("screens.notification.createError"),
          },
          successNotification: {
            type: "success",
            message: t("screens.notification.createSuccess"),
          },
        },
        {
          onError: (error, variables, context) => {
            console.log("handleUpdateVisitorRegistration", {
              error,
              variables,
              context,
            });
          },
          onSuccess: (data, variables, context) => {
            console.log("handleUpdateVisitorRegistration", {
              data,
              variables,
              context,
            });
            list("screens");
          },
        }
    );
  };

    // const {
    //     selectProps: { onSearch, showSearch, ...restSelectProps },
    //     queryResult,
    // } = useSelect<IDevice>({
    //     resource: "devices",
    //     optionLabel: "name",
    //     pagination: {
    //         current: 1,
    //         pageSize: 99999, // use the large PageSize to get full
    //     },
    // });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical" onFinish={(values) => handleCreateScreen(values)}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("screens.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Điền loại thiết bị"
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.rfidTopic")}
                name="rfidTopic"
                rules={[
                  {
                    required: true,
                      message: "Điền Relay Topic"
                  },
                ]}
            >
                <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.ip")}
                name="ip"
                rules={[
                    {
                        required: true,
                        message: "Điền IP"
                    },
                ]}
            >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.serialNumber")}
                name="serialNumber"
                rules={[
                    {
                        required: true,
                        message: "Điền Serial Number"
                    },
                ]}
            >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.androidId")}
                name="androidId"
                rules={[
                    {
                        required: true,
                        message: "Điền Android Id"
                    },
                ]}
            >
                <Input />
            </Form.Item>
          </Col>
          {/*<Col xs={24} md={12}>*/}
          {/*  <Form.Item*/}
          {/*    label={t("register.devices")}*/}
          {/*    name="device"*/}
          {/*    rules={[*/}
          {/*        {*/}
          {/*            required: true,*/}
          {/*            message: "Chọn thiết bị"*/}
          {/*        },*/}
          {/*    ]}*/}
          {/*  >*/}
          {/*      <Select*/}
          {/*          {...restSelectProps}*/}
          {/*          showSearch={true}*/}
          {/*          filterOption={(input, option) =>*/}
          {/*              fuzzySearch(*/}
          {/*                  toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),*/}
          {/*                  toLowerCaseNonAccentVietnamese(input)*/}
          {/*              )*/}
          {/*          }*/}
          {/*      />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
        </Row>
      </Form>
    </Create>
  );
};
