import {
  Col,
  CreateButton,
  FilterDropdown,
  Form,
  Icons,
  Input,
  List,
  Row,
  Select,
  Space,
  // Switch,
  Table,
  useSelect,
  // TextField,
  useTable,
  Button,
  Checkbox,
  Tag
} from "@pankod/refine-antd";
import { FilterOutlined } from "@ant-design/icons";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
  LogicalFilter
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";

import {
  TIME_FORMAT,
  USER_ACCESS_TYPES,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_COUNT_DATE_EXPORT,
  TIMEKEEPING_STATUS,
  ATTENDACE_STATUS,
  CUSTOM_DATE_FORMAT
} from "config";
import dayjs from "dayjs";
import { RangePicker } from "components/datepicker";
import { IDepartment, IStaff, ITimekeeping } from "interfaces";
import { useMemo, useState } from "react";
import { TimekeepingExport } from "./timekeepingExport";


const { SearchOutlined, PlusOutlined } = Icons;

export const TimeKeepingList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const { tableProps, searchFormProps, setFilters, filters } = useTable<
  ITimekeeping,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IStaff>();

  const { run: searchUser } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });
  
  // const invalidate = useInvalidate();

  // const handleUpdateAllowedAreaSuccess = useCallback(() => {
  //   invalidate({
  //     resource: "staffs",
  //     invalidates: ["list"],
  //   });
  // }, [invalidate]);

  const [rangeDate, setRangeDate] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | undefined
  >();

  const {
    selectProps: { ...restSelectDepartmentProps },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    selectProps: { showSearch, ...restSelectStaffProps },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    optionValue: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const departmentOptions = useMemo(
    () => restSelectDepartmentProps.options,
    [restSelectDepartmentProps]
  );

  const staffOptions = useMemo(
    () => restSelectStaffProps.options,
    [restSelectStaffProps]
  );
 
  const userAttendanceTypeOptions = useMemo(
    () =>
    ATTENDACE_STATUS.map((status) => ({
        value: status,
        label: TIMEKEEPING_STATUS().find(
          (item) => item.value === status
        )?.label
      })),
    []
  );

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchUser()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Space>
            {/* <Form.Item name="isExportAttendance">
              <Checkbox onChange={() => attendanceUser()}>Lọc dữ liệu chấm công</Checkbox>
            </Form.Item> */}
            <TimekeepingExport data={filters} />
            {canCreate && (
              <CreateButton type="primary" icon={<PlusOutlined />}>
                {t("buttons.create_new")}
              </CreateButton>
            )}
          </Space>
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />
        
        <Table.Column
          dataIndex="date"
          key="date"
          title={t("timekeeping.fields.date")}
          filterDropdown={(props) => (
            <div
              style={{
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 16,
              }}
            >
              <RangePicker
                format={DATE_TIME_FORMAT}
                showTime
                showSecond={false}
                value={rangeDate}
                onCalendarChange={(rangeValue) => {
                  setRangeDate(rangeValue as [dayjs.Dayjs, dayjs.Dayjs]);
                }}
              />
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setFilters((filters) => {
                      const finalFilters: CrudFilters = filters
                        .filter((filter) => {
                          const lFilter = filter as LogicalFilter;
                          return (
                            lFilter.field !== "startTime" &&
                            lFilter.field !== "endTime"
                          );
                        })
                        .concat({
                          field: "startTime",
                          operator: "eq",
                          value: rangeDate?.[0]?.toISOString(),
                        })
                        .concat({
                          field: "endTime",
                          operator: "eq",
                          value: rangeDate?.[1]?.toISOString(),
                        });
                      return finalFilters;
                    });
                    props.confirm({ closeDropdown: true });
                  }}
                >
                  <FilterOutlined /> {t("buttons.filter")}
                </Button>
                <Button
                  danger
                  size="small"
                  onClick={() => {
                    setFilters((filters) => {
                      const finalFilters: CrudFilters = filters.filter(
                        (filter) => {
                          const lFilter = filter as LogicalFilter;
                          return (
                            lFilter.field !== "startTime" &&
                            lFilter.field !== "endTime"
                          );
                        }
                      );
                      return finalFilters;
                    });
                    props.confirm({ closeDropdown: true });
                  }}
                >
                  {t("buttons.clear")}
                </Button>
              </Space>
            </div>
          )}
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("timekeeping.fields.name")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder="Họ và tên"
                options={staffOptions}
                showSearch={true}
                filterOption={(inputValue, option: any) => {
                  console.log(option)
                  return option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                }
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="userType"
          key="userType"
          title={t("device_logs.fields.userType")}
          render={(userType) =>
            userType
              ? t(`device_logs.fields.userType_${userType}`, userType)
              : ""
          }
          
        />

        <Table.Column
          dataIndex="startTime"
          key="startTime"
          title={t("timekeeping.fields.startTime")}
          render={(exp) => exp != null ? dayjs(exp).format(TIME_FORMAT): "Chưa xác định"}
        />

        <Table.Column
          dataIndex="endTime"
          key="endTime"
          title={t("timekeeping.fields.endTime")}
          render={(exp) => exp != null ? dayjs(exp).format(TIME_FORMAT): "Chưa xác định"}
        />

        <Table.Column
          dataIndex={["department", "name"]}
          key="department"
          title={t("timekeeping.fields.department")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("timekeeping.fields.department")}
                options={departmentOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="onTime"
          key="onTime"
          title={t("timekeeping.fields.status")}
          render={(status: string) => {
            const statusItem = TIMEKEEPING_STATUS(t).find(
              (item) => item.value === status
            );
            return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("timekeeping.fields.status")}
                options={userAttendanceTypeOptions}
              />
            </FilterDropdown>
          )}
        />

        {/* <Table.Column
          dataIndex="mobile"
          key="mobile"
          title={t("timekeeping.fields.phone")}
        /> */}

        {/* <Table.Column
          dataIndex="email"
          key="email"
          title={t("staffs.fields.email")}
        /> */}

        {/* <Table.Column
          dataIndex="image"
          key="image"
          title={t("staffs.fields.image")}
          render={(image) => image && <Avatar src={<Image src={image} />} />}
        /> */}

        {/* <Table.Column<IStaff>
          dataIndex="staff-status"
          key="staff-status"
          title={t("staffs.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.staffs,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("staffs.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("staffs.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        {/* <Table.Column<IStaff>
          dataIndex="allowed_areas"
          key="allowed_areas"
          title={t("staffs.fields.allowed_areas")}
          render={(_, record) => (
            <ButtonSelectAllowedAreas
              userAccess={record.userAccess}
              onSuccess={handleUpdateAllowedAreaSuccess}
            />
          )}
        /> */}
      </Table>
    </List>
  );
};
