import {CreateButton, DeleteButton, Icons, List, Space, Table, useSelect} from "@pankod/refine-antd";
import {useNavigation, useTranslate} from "@pankod/refine-core";
import {
  ButtonRegisterCard,
  ButtonRegisterFace,
  ButtonRegisterFinger,
} from "components/registers";
import {useEffect, useMemo, useState} from "react";
import axiosInstance from "axiosInstance";
import { useApiUrl } from "@pankod/refine-core";
import {IArea, IAreaUser, IGroup, IStaff} from "interfaces";
import AddAreaUser2 from "./AddAreaUser";
const { EditOutlined, DeleteOutlined, PlusOutlined } = Icons;
interface UserTableProps {
  staffList: any;
  area: any;
  areaUserList: any;
}

export const AreaUserTable2: React.FC<UserTableProps> = ({ staffList, area, areaUserList}) => {
  const t = useTranslate();
  const [isRefetching, setIsRefetching] = useState(false);
  const apiUrl = useApiUrl();
  const [dataUsers, setdataUsers] = useState([]);


  let areaUserTmp: IAreaUser[] = [];
  areaUserList?.data?.map((a: any) => areaUserTmp.push(a));
  let staffTmp: IStaff[] = [];
  staffList?.data.map((s: any) => staffTmp.push(s));
  // let staffId = areaUserTmp.filter((au) => au.areaId === area?.id)[0]?.staffId;
  // let staff: IStaff = staffTmp.filter((s) => s?.id === staffId)[0];
  let dataList = areaUserTmp?.filter((au) => au?.areaId === area?.id)
  const dataSet = dataList?.map((item: any, index: number) => ({
    ...item,
    key: index + 1,
    staff: `${staffTmp.filter((s) => s?.id === item?.staffId)[0]?.fullName} - ${staffTmp.filter((s) => s?.id === item?.staffId)[0]?.department?.name}`,
    area: area?.name,
    areaUserId: item?.id
  }));
  console.log("******** area info*******")
  console.log(dataSet)

  const { list } = useNavigation();

  return (
    <>
      {
        !isRefetching && (
          <List
            breadcrumb={null}
            headerButtons={
              <AddAreaUser2 area={area} />
            }
            canCreate={false}
          >
            <Table rowKey="key" dataSource={dataSet}>
              <Table.Column dataIndex="key" key="key" title={"#"} />

              <Table.Column
                dataIndex="staff"
                key="staff"
                title={t("staffs.fields.name")}
              />

              <Table.Column
                dataIndex="area"
                key="area"
                title="Khu vực"
              />

              <Table.Column<any>
                dataIndex="actions"
                title="Hành động"
                render={(_, record) => {
                  // console.log("********* area user record 11111***********");
                  // console.log(record)
                  return (
                    <Space>
                      <DeleteButton
                        hideText
                        size="small"
                        resourceName="area-user"
                        recordItemId={record.areaUserId}
                        errorNotification={{
                          type: "error",
                          message: t("areas.notification.deleteError"),
                        }}
                        successNotification={{
                          type: "success",
                          message: t("areas.notification.deleteSuccess"),
                        }}
                        onSuccess={()=>window.location.reload()}
                      />
                    </Space>
                  )}}
              />
            </Table>
          </List>
        )
      }
    </>
  );
};