import { IFloor } from "interfaces";
import { CrudFilters, CrudOperators } from "@pankod/refine-core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
}

export function toArray(value: any) {
  return Array.isArray(value) ? value : [value];
}

export const checkScrollToBottom = (element: HTMLElement, offset = 0) => {
  return (
    element.scrollTop + element.clientHeight + offset >= element.scrollHeight
  );
};

export function downloadData(data: any, filename: string) {
  const href = URL.createObjectURL(data);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function sortFloors(floors: IFloor[]) {
  return floors.sort((a, b) =>
    a.floorIndex && b.floorIndex ? a.floorIndex - b.floorIndex : 0
  );
}

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
    case "gte":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
    case "eq":
    default:
      return "";
  }
};

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters) {
    filters.forEach((filter) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[@pankod/refine-simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        );
      }

      if ("field" in filter) {
        const { field, operator, value } = filter;

        if (field === "q") {
          queryFilters[field] = value;
          return;
        }

        // console.log({ operator });
        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    });
  }

  return queryFilters;
};

export const exportToCSV = (apiData: any, fileName: string) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  var Heading = [
    ["Thứ tự", "Ngày chấm công", "Họ và tên", "Số ĐT", "Phòng ban", "Vào", "Ra", "Người dùng", "Trạng thái chấm công",],
  ];

  const ws = XLSX.utils.json_to_sheet(apiData);
  XLSX.utils.sheet_add_aoa(ws, Heading);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataSave = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataSave, fileName + fileExtension);
};