import { Space, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  ButtonRegisterCard,
  ButtonRegisterFace,
  ButtonRegisterFinger,
} from "components/registers";
import { useEffect, useState } from "react";
import axiosInstance from "axiosInstance";
import { useApiUrl } from "@pankod/refine-core";
import { IStaff } from "interfaces";

interface UserTableProps {
  code: string;
  staff: any;
}

export const UserTable: React.FC<UserTableProps> = ({
  code, staff
}) => {
  const t = useTranslate();
  const [isRefetching, setIsRefetching] = useState(false);
  const apiUrl = useApiUrl();
  const [dataUsers, setdataUsers] = useState([]);
  const staffInfo = staff;

  const handleExportAttendance = async () => {
    try {
      setIsRefetching(true);
      const { data } = await axiosInstance.get(`${apiUrl}/devices/${code}/users`, {
      });

      const dataSet = data.usersIndevice.map((item: any, index: number) => ({
        ...item,
        key: index + 1,
        name: item.name,
        department: (staffInfo?.filter((s: { userAccess: { accessCode: any; }; }) => s?.userAccess?.accessCode === item.code))[0]?.department?.name,
        userAccess: (staffInfo?.filter((s: { userAccess: { accessCode: any; }; }) => s?.userAccess?.accessCode === item.code))[0]?.userAccess,
        fullName: item.name
      }));
      // console.log("****** data set *****")
      // console.log(dataSet)
      setdataUsers(dataSet);
;
      
    } catch (error: any) {
      
    } finally {
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    if (code && staff) {
      // console.log("***** start get users *****");
      handleExportAttendance();
    }
  }, [code, staff]);


  // console.log("*******8 departmentOptions *******")
  // console.log(accessOptions)

  return (
    <>
    {
      !isRefetching && (
        <Table rowKey="key" dataSource={dataUsers}>
          <Table.Column dataIndex="key" key="key" title={"#"} />

          <Table.Column
            dataIndex="name"
            key="name"
            title={t("staffs.fields.name")}
          />

          <Table.Column
            dataIndex="department"
            key="department"
            title={t("staffs.fields.department")}
          />

        <Table.Column<IStaff>
          dataIndex="registers"
          title={t("staffs.fields.registers")}
          render={(_, record) => {
            // console.log(record)
            return (
            <Space>
              <ButtonRegisterCard
                userAccess={record.userAccess}
                username={record.fullName}
              />
              <ButtonRegisterFace
                userAccess={record.userAccess}
                username={record.fullName}
              />
              <ButtonRegisterFinger
                userAccess={record.userAccess}
                username={record.fullName}
              />
            </Space>
          )}}
        />
        </Table>
      )
    }
    </>
  );
};