import { useState, useCallback } from "react";
import axiosInstance from "axiosInstance";
import { STORAGE_API} from "config";

export default function useScreenSaverUpload() {
  const [progress, setProgress] = useState(0);

  const uploadFile = useCallback(async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    formData.append("file", file);

    try {
      const res = await axiosInstance.post(`${STORAGE_API}/libraries/upload?isPublic=true&service=ACCESS_CONTROL`, formData, config);
      onSuccess(res.data?.data, file);
    } catch (err) {
      onError({ err });
    }
  }, []);

  return {
    progress,
    uploadFile,
  };
}
