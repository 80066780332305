import {
  CreateButton,
  DeleteButton,
  EditButton,
  Icons,
  List,
  ShowButton,
  Space,
  Table, TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";

import { IArea } from "interfaces";

const {
  // SearchOutlined,
  PlusOutlined,
} = Icons;

export const SpecialAreaList: React.FC<IResourceComponentsProps> = ({ canCreate }) => {
  const t = useTranslate();

  const {
    tableProps,
    // searchFormProps
  } = useTable<IArea, HttpError, { q: string }>({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
    initialFilter: [
      {
        field: "type",
        operator: "eq",
        value: "SERVER",
      },
    ],
  });

  // const { form, ...restFormProps } = searchFormProps;

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IArea>();

  // const { run: searhArea } = useDebounceFn(() => form?.submit(), {
  //   wait: 300,
  // });

  return (
    <List
      title="Quản lý khu vực đặc biệt"
      headerButtons={
        canCreate ? (
          <CreateButton type="primary" icon={<PlusOutlined />}>
            {t("buttons.create_new")}
          </CreateButton>
        ) : null
      }
    >
      {/* <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searhArea()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row> */}

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("areas.fields.name")}
        />

        <Table.Column
            dataIndex="type"
            key="type"
            title={t("areas.fields.type")}
            render={(type) => <TextField value={type === "SERVER" ? "Khu vực server": "Khu vực chung"} />}
        />

        <Table.Column
          dataIndex="code"
          key="code"
          title={t("areas.fields.code")}
        />

        {/* <Table.Column<IArea>
          dataIndex="area-status"
          key="area-status"
          title={t("areas.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.areas,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("areas.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("areas.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        <Table.Column<IArea>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/*<ShowButton hideText size="small" recordItemId={record.id} />*/}
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("areas.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("areas.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
