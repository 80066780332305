import {Col, Create, Form, Input, Row, Select, Typography, useForm} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl, useCustomMutation, useNavigation, useTranslate} from "@pankod/refine-core";

import {IGuest, IScreenSaver} from "interfaces";
import {getFileUrlsFromEvent, getFileValueProps} from "../../utils/formHelper";
import {UploadImage, UploadScreenSaverImage} from "../../components/upload";
import {useEffect, useState} from "react";
import {ORIENTATION_TYPE_LIST} from "../../config";
const { Text } = Typography;

export const ScreenSaverCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IScreenSaver>({
    errorNotification: {
      type: "error",
      message: t("screensaver.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("screensaver.notification.createSuccess"),
    },
  });

  const { mutate: updateScreenSaverFile } = useCustomMutation<IScreenSaver>();
  const apiUrl = useApiUrl();
  const initDescription = "";

  const [description, setDescription] = useState(initDescription);

  useEffect(() => setDescription(initDescription), [initDescription]);

  const { list } = useNavigation();

  const handleUploadScreenImage = async (
      props: IScreenSaver
  ) => {
    console.log("******* update handleUploadScreenImage *******");
    console.log(props);
    updateScreenSaverFile(
        {
          url: `${apiUrl}/screensaver`,
          method: "post",
          values: {
            name: props.name,
            orientation: props.orientation,
            status: "ACTIVE",
            fileId: props?.fileId?.split('download/')[1],
            description: description,
          },
          errorNotification: {
            type: "error",
            message: t("screensaver.notification.createError"),
          },
          successNotification: {
            type: "success",
            message: t("screensaver.notification.createSuccess"),
          },
        },
        {
          onError: (error, variables, context) => {
            console.log("handleUpdateVisitorRegistration", {
              error,
              variables,
              context,
            });
          },
          onSuccess: (data, variables, context) => {
            console.log("handleUpdateVisitorRegistration", {
              data,
              variables,
              context,
            });
            list("screensaver");
          },
        }
    );
  };

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical" onFinish={(values) => handleUploadScreenImage(values)}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("screensaver.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("screensaver.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label="Định hướng"
                name="orientation"
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <Select options={ORIENTATION_TYPE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label="Tải ảnh lên"
                name="fileId"
                getValueFromEvent={(e) => {
                  console.log("******** getValueFromEvent *******")
                  console.log(e)
                  const fileUrls = getFileUrlsFromEvent(e);
                  return fileUrls.length < 1 ? "" : fileUrls[0];
                }}
                getValueProps={(value) => {
                  console.log("******** getValueProps *******")
                  console.log(value)
                  const imgs = getFileValueProps(value, [
                    "Ảnh màn hình chờ",
                  ]);
                  return {
                    defaultFileList: imgs,
                  };
                }}
            >
              <UploadScreenSaverImage maxCount={1} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Text
                style={{
                  fontWeight: 600,
                }}
            >
              {"Mô tả"}
            </Text>
            <Input.TextArea
                rows={4}
                style={{ width: "100%" }}
                disabled={false}
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
            />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
