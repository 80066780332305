import {
  Button,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  Modal,
  PageHeader,
  Row,
  useForm,
  useModalForm,
  Typography,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useNavigation,
} from "@pankod/refine-core";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import DatePicker from "components/datepicker";
import { DATE_TIME_FORMAT } from "config";
import {IArea, IDepartment, IGuest, IStaff, IVisitor, IVisitorRegistration} from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import {getFileUrlsFromEvent, getFileValueProps} from "../../utils/formHelper";
import {UploadImage} from "../../components/upload";

const { PlusOutlined } = Icons;
const { Text } = Typography;

export const GuestEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();

  const { list } = useNavigation();

  const {
    formProps: { form: formRegistration, ...restFormRegistration },
    saveButtonProps,
    formLoading,
    queryResult,
  } = useForm<IGuest>({
    errorNotification: {
      type: "error",
      message: t("visitors_registrations.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("visitors_registrations.notification.editSuccess"),
    },
  });

  const {
    modalProps,
    formProps: { onFinish: _, form: formAddVisitor, ...restModalFormProps },
    show: showAddVisitor,
    close: closeAddVisitor,
    visible: visibleAddVisitor,
  } = useModalForm({
    action: "edit",
  });

  const {
    selectProps: {
      showSearch: showStaffSearch,
      onSearch: onStaffSearch,
      ...restSelectStaffProps
    },
    queryResult: { data: staffList },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const selectStaffOptions = useMemo(() => {
    return (
      staffList?.data?.map((staff) => ({
        value: staff.id,
        label: `${staff.fullName} (${staff.department?.name})`,
      })) ?? []
    );
  }, [staffList?.data]);

  const apiUrl = useApiUrl();

  const { mutate: updateStaff } = useCustomMutation<IGuest>();

  const initDescription = queryResult?.data?.data?.description || "";

  const [description, setDescription] = useState(initDescription);

  useEffect(() => setDescription(initDescription), [initDescription]);

  const handleUpdateGuest = async (
    props: IGuest
  ) => {
    console.log("******* update staff *******");
    console.log(props);
    updateStaff(
      {
        url: `${apiUrl}/guests/${queryResult?.data?.data?.id}`,
        method: "put",
        values: {
          registrationName: props.registrationName,
          fullName: props.fullName,
          citizenIdentityNo: props.citizenIdentityNo,
          mobile: props.mobile,
          vehicleNumber: props.vehicleNumber,
          startDate: props.startDate,
          endDate: props.endDate,
          timeCheckin: props.timeCheckin,
          staff: props.staff?.id,
          citizenIdFontImage: props.citizenIdFontImage,
          citizenIdBackImage: props.citizenIdBackImage,
          area: props.area?.map((area) => ({
            id: area,
          })),
          description: description,
        },
        errorNotification: {
          type: "error",
          message: t("visitors_registrations.notification.editError"),
        },
        successNotification: {
          type: "success",
          message: t("visitors_registrations.notification.editSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            error,
            variables,
            context,
          });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            data,
            variables,
            context,
          });
          list("guests");
        },
      }
    );
  };

  useEffect(() => {
    console.log("**** queryResult1 *******")
    if (queryResult && formRegistration) {
      console.log("**** queryResult *******")
      console.log(queryResult)
      formRegistration?.setFieldsValue({
        area: queryResult?.data?.data?.area?.map((area) => area?.id),
      });
    }
  }, [formRegistration, queryResult]);

  const {
    selectProps: { onSearch, showSearch, ...restSelectAreaProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <>
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          disabled: false,
        }}
        isLoading={formLoading}
      >
        <Form
          form={formRegistration}
          {...restFormRegistration}
          layout="vertical"
          onFinish={(values) => handleUpdateGuest(values)}
        >
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.name")}
                name="registrationName"
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.nameRequired"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label="Tên khách"
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Tên khách không được để trống",
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label={t("visitors_registrations.fields.area")}
                  name="area"
                  rules={[
                    {
                      required: true,
                      message: t("visitors_registrations.errors.areaRequired"),
                    },
                  ]}
              >
                <Select
                    {...restSelectAreaProps}
                    showSearch={true}
                    mode="multiple"
                    filterOption={(input, option) =>
                        fuzzySearch(
                            toLowerCaseNonAccentVietnamese(
                                String(option?.label ?? "")
                            ),
                            toLowerCaseNonAccentVietnamese(input)
                        )
                    }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label="CCCD/CMND"
                  name="citizenIdentityNo"
                  rules={[
                    {
                      required: true,
                      message: "CCCD/CMND không được để trống",
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label="Số điện thoại"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Số điện thoại không được để trống",
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label="Biển số xe"
                  name="vehicleNumber"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Người dẫn"
                name={["staff", "id"]}
                rules={[
                  {
                    required: true,
                    message: "Yêu cầu chọn người dẫn",
                  },
                ]}
              >
                <Select
                  {...restSelectStaffProps}
                  options={selectStaffOptions}
                  showSearch={true}
                  // mode="single"
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>

            {/*<Col xs={24} md={12}>*/}
            {/*  <Form.Item name="key">*/}
            {/*    <Input hidden />*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.startTime")}
                name="startDate"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t(
                      "visitors_registrations.errors.startTimeRequired"
                    ),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.endTime")}
                name="endDate"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.endTimeRequired"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label="Thời gian check in"
                  name="timeCheckin"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : "",
                  })}
              >
                <DatePicker
                    style={{ width: "100%" }}
                    format={DATE_TIME_FORMAT}
                    showTime
                    disabledDate={(d) => {
                      const today = new Date();
                      return d && d.isBefore(today) && !d.isSame(today, "day");
                    }}
                />
              </Form.Item>
            </Col>

            {/*<Col xs={24} md={12}>*/}
            {/*  <Form.Item*/}
            {/*      label="Người dẫn"*/}
            {/*      name={["createdBy", "username"]}*/}
            {/*      disable={true}*/}
            {/*  >*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}

            <Col xs={24} md={12}>
              <Form.Item label="Người tạo" name={["createdBy", "username"]}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item name="key">
                <Input hidden />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Text
                  style={{
                    fontWeight: 600,
                  }}
              >
                {"Mục đích"}
              </Text>
              <Input.TextArea
                  rows={4}
                  style={{ width: "100%" }}
                  disabled={false}
                  value={description}
                  onChange={(evt) => setDescription(evt.target.value)}
              />
            </Col>


            <Col xs={24} md={12}>
              <Form.Item
                  label={t(
                      "visitors_registrations.visitorList.fields.citizenIdFontImage"
                  )}
                  name="citizenIdFontImage"
                  getValueFromEvent={(e) => {
                    const fileUrls = getFileUrlsFromEvent(e);
                    return fileUrls.length < 1 ? "" : fileUrls[0];
                  }}
                  getValueProps={(value) => {
                    const imgs = getFileValueProps(value, [
                      t(`visitors_registrations.visitorList.fields.citizenIdFontImage`),
                    ]);
                    return {
                      defaultFileList: imgs,
                    };
                  }}
              >
                <UploadImage maxCount={1} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                  label={t(
                      "visitors_registrations.visitorList.fields.citizenIdBackImage"
                  )}
                  name="citizenIdBackImage"
                  getValueFromEvent={(e) => {
                    const fileUrls = getFileUrlsFromEvent(e);
                    return fileUrls.length < 1 ? "" : fileUrls[0];
                  }}
                  getValueProps={(value) => {
                    const imgs = getFileValueProps(value, [
                      t(`visitors_registrations.visitorList.fields.citizenIdBackImage`),
                    ]);
                    return {
                      defaultFileList: imgs,
                    };
                  }}
              >
                <UploadImage maxCount={1} />
              </Form.Item>
            </Col>

            {/*<Col xs={24} md={12}>*/}
            {/*  <Form.Item name={VISITORS_KEY}>*/}
            {/*    <Input hidden />*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}

            {/*<Col xs={24}>*/}
            {/*  <PageHeader*/}
            {/*    backIcon={null}*/}
            {/*    style={{ padding: 0 }}*/}
            {/*    title={*/}
            {/*      <Text*/}
            {/*        style={{*/}
            {/*          fontSize: 16,*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {t("visitors_registrations.visitorList.titles.list")}*/}
            {/*      </Text>*/}
            {/*    }*/}
            {/*    extra={[*/}
            {/*      <Button*/}
            {/*        key="add-visitor"*/}
            {/*        type="primary"*/}
            {/*        icon={<PlusOutlined />}*/}
            {/*        onClick={() => showAddVisitor()}*/}
            {/*      >*/}
            {/*        {t("visitors_registrations.visitorList.buttons.addVisitor")}*/}
            {/*      </Button>,*/}
            {/*    ]}*/}
            {/*  />*/}
            {/*</Col>*/}

            {/*<Col xs={24}>*/}
            {/*  <VisitorTable*/}
            {/*    visitorList={visitorList}*/}
            {/*    onEdit={(record) => {*/}
            {/*      showAddVisitor();*/}
            {/*      setTimeout(*/}
            {/*        () => formAddVisitor?.setFieldsValue({ ...record }),*/}
            {/*        0*/}
            {/*      );*/}
            {/*    }}*/}
            {/*    onDelete={(record) => {*/}
            {/*      deleteVisitor(record);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Col>*/}
          </Row>
        </Form>
      </Edit>

      {/*<Modal*/}
      {/*  {...modalProps}*/}
      {/*  title={t("visitors_registrations.visitorList.fields.name")}*/}
      {/*>*/}
      {/*  <Form*/}
      {/*    {...restModalFormProps}*/}
      {/*    layout="vertical"*/}
      {/*    form={formAddVisitor}*/}
      {/*    onFinish={() =>*/}
      {/*      formAddVisitor*/}
      {/*        ?.validateFields()*/}
      {/*        .then((values) => addOrEditVisitor(values))*/}
      {/*        .catch((error) => console.log("error", error))*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {visibleAddVisitor && <AddVisitorFormContent />}*/}
      {/*  </Form>*/}
      {/*</Modal>*/}
    </>
  );
};
