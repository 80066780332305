import {
    Button,
    Col,
    Row,
    Form,
    Icons,
    Input,
    Modal,
    PageHeader,
    Show,
    useForm,
    useModalForm,
    Typography,
    Select,
    useSelect, Space, Popconfirm, Table, Tag,
} from "@pankod/refine-antd";
import TextBox from "components/textBox";
import {
  useApiUrl,
  useCustomMutation,
  useNavigation, useShow,
} from "@pankod/refine-core";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import DatePicker from "components/datepicker";
import {DATE_TIME_FORMAT, VISITOR_REGISTRATION_STATUS_LIST} from "config";
import {IArea, IDepartment, IGuest, IStaff, IVisitor, IVisitorRegistration} from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import {getFileUrlsFromEvent, getFileValueProps} from "../../utils/formHelper";
import {UploadImage} from "../../components/upload";
import useUserRole from "../../hooks/useUserRole";
import {ModalPreviewImage} from "../../components/previewImage";
import {ButtonRegisterCard, ButtonRegisterFace, ButtonRegisterFinger} from "../../components/registers";

const { PlusOutlined } = Icons;
const { Text } = Typography;

export const GuestShow: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();

  const {
    selectProps: {
      showSearch: showDeparmentSearch,
      onSearch: onStaffSearch,
      ...restSelectStafftProps
    },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const { hasSecurityRole, hasDepartmentRole } = useUserRole();

  const { goBack } = useNavigation();

  const { queryResult } = useShow<IGuest>();

  const { data, isLoading } = queryResult;

  const record = data?.data;

  const apiUrl = useApiUrl();

  const initComment = useMemo(() => record?.comment || "", [record]);

  const [comment, setComment] = useState(initComment);

  useEffect(() => setComment(initComment), [initComment]);

  const description = record?.description || "";

  const canApproveOrReject = useMemo(
      () => record?.status === "wait_for_approved",
      [record]
  );

  const { mutate: updateApproveStatus } =
      useCustomMutation<IGuest>();
  const doApproveOrReject = async (
      record: IGuest,
      isApprove: boolean
  ) => {
    updateApproveStatus(
        {
          url: `${apiUrl}/guests/action`,
          method: "post",
          values: {
            guest: { ...record, comment },
            action: isApprove ? "approved" : "rejected",
          },
          errorNotification: {
            type: "error",
            message: isApprove
                ? t("visitors_registrations.notification.approveError")
                : t("visitors_registrations.notification.rejectError"),
          },
          successNotification: {
            type: "success",
            message: isApprove
                ? t("visitors_registrations.notification.approveSuccess")
                : t("visitors_registrations.notification.rejectSuccess"),
          },
        },
        {
          onSuccess() {
            goBack();
          },
        }
    );
  };

  console.log("********* show record *********")
  console.log(record)

  return (
    <>
      <Show
          isLoading={isLoading}
          canEdit={record?.status === "created" && hasDepartmentRole}
      >
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <TextBox
                label={t("visitors_registrations.fields.name")}
                value={record?.registrationName}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="Tên khách"
                  value={record?.fullName}
              />
            </Col>

              <Col xs={24} md={12}>
                  <TextBox
                      label={t("visitors_registrations.fields.area")}
                      value={
                          <>
                              {record?.area?.map((area) => (
                                  <Tag key={area.id}>{area.name}</Tag>
                              ))}
                          </>
                      }
                  />
              </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="CCCD/CMND"
                  value={record?.citizenIdentityNo}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="Số điện thoại"
                  value={record?.mobile}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="Biển số xe"
                  value={record?.vehicleNumber}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="Người dẫn"
                  value={
                    `${record?.staff?.fullName} - ${record?.staff?.department?.name}`
                  }
              />
            </Col>

            {/*<Col xs={24} md={12}>*/}
            {/*  <Form.Item name="key">*/}
            {/*    <Input hidden />*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}

            <Col xs={24} md={12}>
              <TextBox
                  label={t("visitors_registrations.fields.startTime")}
                  value={
                    record?.startDate ? dayjs(record?.startDate).format(DATE_TIME_FORMAT) : "--"
                  }
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label={t("visitors_registrations.fields.endTime")}
                  value={
                    record?.endDate ? dayjs(record?.endDate).format(DATE_TIME_FORMAT) : "--"
                  }
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label="Thời gian check in"
                  value={
                    record?.timeCheckin ? dayjs(record?.timeCheckin).format(DATE_TIME_FORMAT) : "--"
                  }
              />
            </Col>

            {/*<Col xs={24} md={12}>*/}
            {/*  <Form.Item*/}
            {/*      label="Người dẫn"*/}
            {/*      name={["createdBy", "username"]}*/}
            {/*      disable={true}*/}
            {/*  >*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}

            <Col xs={24} md={12}>
              <TextBox
                  label="Người tạo"
                  value={
                    `${record?.createdBy}`
                  }
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label={t("visitors_registrations.fields.status")}
                  value={
                    VISITOR_REGISTRATION_STATUS_LIST(t).find(
                        (item) => item.value === record?.status
                    )?.label
                  }
              />
            </Col>

            <Col xs={24} md={12}>
              {record?.status === "approved" && (
                  <>
                      <Space wrap>
                          <ButtonRegisterCard
                              userAccess={record.userAccess}
                              username={record.fullName}
                          />
                          <ButtonRegisterFace
                              userAccess={record.userAccess}
                              username={record.fullName}
                          />
                          <ButtonRegisterFinger
                              userAccess={record.userAccess}
                              username={record.fullName}
                          />
                      </Space>
                  </>
              )}
            </Col>

              <Col xs={24} md={12}>
              </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label={t(
                      "visitors_registrations.visitorList.fields.citizenIdFontImage"
                  )}
                  value={<ModalPreviewImage
                      url={record?.citizenIdFontImage}
                      name={t(
                          "visitors_registrations.visitorList.fields.citizenIdFontImage"
                      )}
                  />}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                  label={t(
                      "visitors_registrations.visitorList.fields.citizenIdBackImage"
                  )}
                 value={record?.citizenIdBackImage && (
                      <ModalPreviewImage
                          url={record.citizenIdBackImage}
                          name={t(
                              "visitors_registrations.visitorList.fields.citizenIdBackImage"
                          )}
                      />
                  )}
                />
            </Col>

            <Col xs={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                  <TextBox
                      label="Mục đích"
                      labelStyles={{
                          fontWeight: 600,
                          color: "rgba(0, 0, 0, 0.85)",
                      }}
                      value={description}
                      valueStyles={{
                          minHeight: 66,
                      }}
                  />
              </Space>
            </Col>

            <Col xs={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                  {hasSecurityRole ? (
                      <>
                          <Text
                              style={{
                                  fontWeight: 600,
                              }}
                          >
                              {t("visitors_registrations.fields.comment")}
                          </Text>
                          <Input.TextArea
                              rows={4}
                              style={{ width: "100%" }}
                              disabled={!canApproveOrReject}
                              value={comment}
                              onChange={(evt) => setComment(evt.target.value)}
                          />
                      </>
                  ) : (
                      <TextBox
                          label={t("visitors_registrations.fields.comment")}
                          labelStyles={{
                              fontWeight: 600,
                              color: "rgba(0, 0, 0, 0.85)",
                          }}
                          value={record?.comment || ""}
                          valueStyles={{
                              minHeight: 66,
                          }}
                      />
                  )}
              </Space>
            </Col>

            <Col xs={24}>
              <Row>
                <Col flex="auto"></Col>
                <Col flex={0}>
                  {hasSecurityRole && (
                      <Space>
                        <Popconfirm
                            title={t("visitors_registrations.confirm.wantToReject")}
                            onConfirm={() => doApproveOrReject(record!!, false)}
                            onCancel={() => null}
                            okText={t("visitors_registrations.confirm.agree")}
                            cancelText={t("buttons.cancel")}
                            okType="primary"
                            disabled={!canApproveOrReject}
                        >
                          <Button danger disabled={!canApproveOrReject}>
                            {t("visitors_registrations.buttons.reject")}
                          </Button>
                        </Popconfirm>

                        <Popconfirm
                            title={t("visitors_registrations.confirm.wantToApprove")}
                            onConfirm={() => doApproveOrReject(record!!, true)}
                            onCancel={() => null}
                            okText={t("visitors_registrations.confirm.agree")}
                            cancelText={t("buttons.cancel")}
                            okType="primary"
                            disabled={!canApproveOrReject}
                        >
                          <Button type="primary" disabled={!canApproveOrReject}>
                            {t("visitors_registrations.buttons.approve")}
                          </Button>
                        </Popconfirm>
                      </Space>
                  )}
                </Col>
              </Row>
            </Col>

          </Row>
      </Show>
      {/*<Modal*/}
      {/*  {...modalProps}*/}
      {/*  title={t("visitors_registrations.visitorList.fields.name")}*/}
      {/*>*/}
      {/*  <Form*/}
      {/*    {...restModalFormProps}*/}
      {/*    layout="vertical"*/}
      {/*    form={formAddVisitor}*/}
      {/*    onFinish={() =>*/}
      {/*      formAddVisitor*/}
      {/*        ?.validateFields()*/}
      {/*        .then((values) => addOrEditVisitor(values))*/}
      {/*        .catch((error) => console.log("error", error))*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {visibleAddVisitor && <AddVisitorFormContent />}*/}
      {/*  </Form>*/}
      {/*</Modal>*/}
    </>
  );
};
