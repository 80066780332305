import {
  Button,
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Popconfirm,
  Row,
  ShowButton,
  Space,
  // Switch,
  Table,
  Tag,
  // TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import dayjs from "dayjs";

import {
  API_RESOURCES,
  DATE_FORMAT, DATE_TIME_FORMAT,
  DATE_TIME_FORMAT2,
  VISITOR_REGISTRATION_STATUS_LIST,
} from "config";
import useUserRole from "hooks/useUserRole";
import {IGuest, IVisitorRegistration, IVisitorRegistrationStatus} from "interfaces";

const { SearchOutlined, PlusOutlined, SendOutlined } = Icons;

export const GuestList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const invalidate = useInvalidate();

  const { hasDepartmentRole } = useUserRole();

  const { tableProps, searchFormProps } = useTable<
    IGuest,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchUser } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  const { mutate: updateApproveStatus } =
      useCustomMutation<IGuest>();

  const apiUrl = useApiUrl();
  const sendToApprove = async (record: IGuest) => {
    updateApproveStatus(
        {
          url: `${apiUrl}/guests/action`,
          method: "post",
          values: {
            guest: { ...record },
            action: "wait_for_approved",
          },
          errorNotification: {
            type: "error",
            message: t("visitors_registrations.notification.sendToApproveError"),
          },
          successNotification: {
            type: "success",
            message: t(
                "visitors_registrations.notification.sendToApproveSuccess"
            ),
          },
        },
        {
          onSuccess() {
            invalidate({
              resource: API_RESOURCES.guests,
              invalidates: ["list"],
            });
          },
        }
    );
  };

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchUser()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && hasDepartmentRole && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="registrationName"
          key="registrationName"
          title={t("visitors_registrations.fields.name")}
        />

        <Table.Column
            dataIndex="fullName"
            key="fullName"
            title="Tên khách"
        />

        {/*<Table.Column*/}
        {/*    dataIndex="citizenIdentityNo"*/}
        {/*    key="citizenIdentityNo"*/}
        {/*    title="Căn cước công dân"*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*    dataIndex="mobile"*/}
        {/*    key="mobile"*/}
        {/*    title="Số điện thoại"*/}
        {/*/>*/}

        {/*<Table.Column*/}
        {/*  dataIndex="createdAt"*/}
        {/*  key="createdAt"*/}
        {/*  title={t("visitors_registrations.fields.createdAt")}*/}
        {/*  render={(value) => (value ? dayjs(value).format(DATE_FORMAT) : "")}*/}
        {/*/>*/}

        <Table.Column
          dataIndex="createdBy"
          key="createdBy"
          title={t("visitors_registrations.fields.createdBy")}
        />

        <Table.Column
            dataIndex={["approvedBy", "username"]}
            key="approvedBy"
            title={t("visitors_registrations.fields.approvedBy")}
        />

        <Table.Column<IGuest>
          dataIndex="startEndTime"
          key="startEndTime"
          title={t("visitors_registrations.fields.startEndTime")}
          width={180}
          render={(_, record) =>
              `${dayjs(record.startDate).format(DATE_TIME_FORMAT)} - ${dayjs(
                  record.endDate
              ).format(DATE_TIME_FORMAT)}`
          }
        />

        {/*<Table.Column*/}
        {/*    dataIndex={["createdBy", "username"]}*/}
        {/*    key="createdBy"*/}
        {/*    title={t("visitors_registrations.fields.createdBy")}*/}
        {/*/>*/}

        {/* <Table.Column
          dataIndex="type"
          key="type"
          title={t("visitors_registrations.fields.type")}
        /> */}

        {/* <Table.Column
          dataIndex={["area", "name"]}
          key="area"
          title={t("visitors_registrations.fields.area")}
        /> */}

        <Table.Column
          dataIndex="status"
          key="status"
          title={t("visitors_registrations.fields.status")}
          render={(status: IVisitorRegistrationStatus) => {
            const statusItem = VISITOR_REGISTRATION_STATUS_LIST(t).find(
              (item) => item.value === status
            );
            return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
          }}
        />

        <Table.Column<IGuest>
            dataIndex="timeCheckin"
            key="timeCheckin"
            title="Thời gian check in"
            width={180}
            render={(_, record) => {
                return record.timeCheckin ? `${dayjs(record.timeCheckin).format(DATE_TIME_FORMAT)}`: "Chưa xác định"
              }
            }
        />

        <Table.Column<IGuest>
            dataIndex="staff"
            key="staff"
            title="Người dẫn - Phòng ban"
            width={180}
            render={(_, record) =>
                `${record.staff?.fullName} - ${record.staff?.department?.name}`
            }
        />

        <Table.Column<IGuest>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {hasDepartmentRole && (
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  disabled={record.status !== "created"}
                />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {hasDepartmentRole && (
              <>
                <Popconfirm
                    title={t("visitors_registrations.confirm.sendToApprove")}
                    onConfirm={() => sendToApprove(record)}
                    onCancel={() => null}
                    okText={t("visitors_registrations.confirm.agree")}
                    cancelText={t("buttons.cancel")}
                    okType="primary"
                    disabled={record.status !== "created"}
                >
                  <Button
                      icon={<SendOutlined />}
                      size="small"
                      disabled={record.status !== "created"}
                  />
                </Popconfirm>
                {record.status === "created" && (
                  <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                      disabled={record.status !== "created"}
                      errorNotification={{
                        type: "error",
                        message: t(
                            "visitors_registrations.notification.deleteError"
                        ),
                      }}
                      successNotification={{
                        type: "success",
                        message: t(
                            "visitors_registrations.notification.deleteSuccess"
                        ),
                      }}
                  />
                )}
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
