import {
  Col,
  Create,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  useForm,
  useSelect
} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl, useCustomMutation, useNavigation, useTranslate} from "@pankod/refine-core";

import {IDevice, IScreen} from "interfaces";
import {fuzzySearch} from "../../utils/searchHelper";
import {toLowerCaseNonAccentVietnamese} from "../../utils/vietnameseHelper";
import {ORIENTATION_TYPE_LIST} from "../../config";
import ChangePin from "../devices/private/ChangePin";
import ChangeScreenPin from "./ChangePin";
import useUserRole from "../../hooks/useUserRole";

export const ScreenEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IScreen>({
    errorNotification: {
      type: "error",
      message: t("screens.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("screens.notification.editSuccess"),
    },
  });

  const { mutate: updateScreen } = useCustomMutation<IScreen>();
  const apiUrl = useApiUrl();

    const {
        selectProps: { onSearch, showSearch, ...restSelectProps },
        queryResult,
    } = useSelect<IDevice>({
        resource: "devices",
        optionLabel: "name",
        pagination: {
            current: 1,
            pageSize: 99999, // use the large PageSize to get full
        },
    });
  const handleUpdateScreen = async (
    props: any
  ) => {
    console.log("******* update handleUpdateScreen *******");
    console.log(props);

    updateScreen(
      {
        url: `${apiUrl}/screens/${formProps?.initialValues?.id}`,
        method: "put",
        values: {
          name: props.name,
          rfidTopic: props?.rfidTopic,
          ip: props?.ip,
          serialNumber: props?.serialNumber,
          androidId: props?.androidId,
          deviceId: props?.deviceId,
          screenMode: props?.screenMode,
          type: props?.type,
        },
        errorNotification: {
          type: "error",
          message: t("screens.notification.editError"),
        },
        successNotification: {
          type: "success",
          message: t("screens.notification.editSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            error,
            variables,
            context,
          });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            data,
            variables,
            context,
          });
        },
      }
    );
  };

  const { hasAdminRole } = useUserRole();

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      headerButtons={({ defaultButtons }) => (
      <>
        {hasAdminRole && <ChangeScreenPin data={formProps} />}
        {defaultButtons}
      </>
    )}>
      <Form {...formProps} layout="vertical" onFinish={(values) => handleUpdateScreen(values)}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("screens.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Điền loại thiết bị"
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.rfidTopic")}
                name="rfidTopic"
                rules={[
                  {
                    required: true,
                      message: "Điền Relay Topic"
                  },
                ]}
            >
                <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.ip")}
                name="ip"
            >
                <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.serialNumber")}
                name="serialNumber"
            >
                <Input disabled={true}/>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
                label={t("screens.fields.androidId")}
                name="androidId"
            >
                <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("register.devices")}
              name="deviceId"
              rules={[
                  {
                      required: true,
                      message: "Chọn thiết bị"
                  },
              ]}
            >
                <Select
                    {...restSelectProps}
                    showSearch={true}
                    filterOption={(input, option) =>
                        fuzzySearch(
                            toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                            toLowerCaseNonAccentVietnamese(input)
                        )
                    }
                />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.screenMode")}
              name="screenMode"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={ORIENTATION_TYPE_LIST(t)} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
