import {Col, Edit, Form, Input, InputNumber, Row, Select, useForm, useSelect} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import {IArea, IAreaUser, IStaff} from "interfaces";
import {AREA_TYPE_LIST, DEVICE_TYPE_LIST} from "../../config";
import { Icons } from "@pankod/refine-antd";
import {SpecialAreaList} from "./list";
import {AreaUserTable2} from "./private/AreaUserTable";
import {SpecialAreaDeviceList} from "./private/deviceList";
const {  UserOutlined  } = Icons;

export const SpecialAreaEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IArea>({
    errorNotification: {
      type: "error",
      message: t("areas.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("areas.notification.editSuccess"),
    },
  });

  const {
    queryResult: { data: staffList },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    optionValue: "userAccess.id",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    queryResult: { data: areaUserList },
  } = useSelect<IAreaUser>({
    resource: "area-user",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.codeRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label="Loại khu vực"
                name="type"
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <Select defaultValue="SERVER" disabled={true} options={AREA_TYPE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
                label="Số lượng user xác thực"
                name="countUserAuth"
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <InputNumber min={1} defaultValue={3} addonBefore={<UserOutlined />} style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={t("areas.fields.description")} name="description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            { areaUserList && staffList && <AreaUserTable2 staffList={staffList} area={formProps?.initialValues} areaUserList={areaUserList} />}
          </Col>
        </Row>
        {formProps?.initialValues?.id && <SpecialAreaDeviceList area={formProps?.initialValues} />}
      </Form>
    </Edit>
  );
};
