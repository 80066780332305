import {
  Col,
  Create,
  Edit,
  Form,
  Input,
  InputNumber, PageHeader,
  Row,
  Select,
  Typography,
  useForm,
  useSelect
} from "@pankod/refine-antd";
import {IResourceComponentsProps, useApiUrl, useCustomMutation, useNavigation, useTranslate} from "@pankod/refine-core";

import {IArea, IDevice, IGroup, IScreen, IStaff} from "interfaces";
import {fuzzySearch} from "../../utils/searchHelper";
import {toLowerCaseNonAccentVietnamese} from "../../utils/vietnameseHelper";
import useUserRole from "../../hooks/useUserRole";
import {useEffect, useMemo, useState} from "react";
import {UserTable} from "../devices/private/userTable";
import {AreaUserTable} from "./private/AreaUserTable";

export const GroupEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { Text } = Typography;

  const { formProps: { form, ...restFormProps }, saveButtonProps, formLoading, queryResult } = useForm<IGroup>({
    errorNotification: {
      type: "error",
      message: t("screens.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("screens.notification.editSuccess"),
    },
  });

  const { mutate: updateGroup } = useCustomMutation<IGroup>();
  const apiUrl = useApiUrl();

  useEffect(() => {
    if (queryResult && form) {
      console.log("**** query ret ******")
      console.log(queryResult)
      const data = queryResult?.data?.data;
      form?.setFieldsValue({
        areas: data?.areas?.map((area) => area?.id),
        staffs: data?.userAccesses?.map((userAccess) => userAccess?.id),
      });
    }
  }, [form, queryResult]);

  const {
    selectProps: {
      onSearch: onSearchArea,
      showSearch: showSearchArea,
      ...restSelectAreaProps
    },
    queryResult: { data: areaList },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "SERVER",
      },
    ],
  });

  const {
    selectProps: {
      showSearch: showStaffSearch,
      onSearch: onStaffSearch,
      ...restSelectStaffProps
    },
    queryResult: { data: staffList },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    optionValue: "userAccess.id",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const [filterStaffList, setFilterStaffList] = useState<IStaff[]>([]);

  useEffect(() => {
    console.log("****** staffListf *******")
    console.log(staffList)
    let filterStaff = staffList?.data.filter((staff) => {
      return staff.userAccess?.areas?.some(area => area.type === "SERVER");
    })
    console.log("****** filter stafff *******")
    console.log(filterStaff)
    let listTmp: IStaff[] = []
    if (filterStaff !== undefined && filterStaff.length > 0) {
      filterStaff.map((staff) => listTmp.push(staff));
    }
    setFilterStaffList(listTmp);
  }, [staffList]);

  const selectStaffOptions = useMemo(() => {
    return (
      filterStaffList.map((staff) => ({
        value: staff.userAccess?.id,
        label: `${staff.fullName} (${staff.department?.name})`,
      })) ?? []
    );
  }, [filterStaffList]);

  const {
    queryResult: { data: areaUserList },
  } = useSelect<IArea>({
    resource: "area-user",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const { list } = useNavigation();

  const handleUpdate = async (
    props: any
  ) => {
    console.log("******* update handleUpdate *******");
    console.log(props);

    updateGroup(
      {
        url: `${apiUrl}/groups/${restFormProps?.initialValues?.id}`,
        method: "put",
        values: {
          name: props.name,
          areaIds: props?.areas,
          userAccessIds: props?.staffs,
        },
        errorNotification: {
          type: "error",
          message: t("screens.notification.editError"),
        },
        successNotification: {
          type: "success",
          message: t("screens.notification.editSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            error,
            variables,
            context,
          });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            data,
            variables,
            context,
          });
          list("groups");
        },
      }
    );
  };

  const { hasAdminRole } = useUserRole();

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
    >
      <Form form={form} {...restFormProps} layout="vertical" onFinish={(values) => handleUpdate(values)}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("screens.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("screens.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("departments.fields.area")}
              name="areas"
              rules={[
                {
                  required: true,
                  message: t("departments.errors.areaRequired"),
                },
              ]}
            >
              <Select
                {...restSelectAreaProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Nhân viên thuộc nhóm"
              name="staffs"
              rules={[
                {
                  required: true,
                  message: "Hãy chọn nhân viên",
                },
              ]}
            >
              <Select
                {...restSelectStaffProps}
                options={selectStaffOptions}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Col xs={24}>
          <PageHeader
            backIcon={null}
            style={{ padding: 0 }}
            title={
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                Nhân viên yêu cầu
              </Text>
            }
          />
        </Col>
        <Col xs={24}>
          { (queryResult?.data?.data?.areas && staffList && areaList && areaUserList) && <AreaUserTable staffList={staffList?.data} areaList={areaList?.data} areas={queryResult?.data?.data?.areas} areaUserList={areaUserList} /> }
        </Col>
      </Form>
    </Edit>
  );
};
