import {
  Button,
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  FilterDropdown,
  Form,
  Icons,
  Input,
  List,
  Row,
  Select,
  Space,
  // Switch,
  Table,
  Tag,
  TextField,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import { DEVICE_CONNECTION_STATE_LIST, DEVICE_TYPE_LIST } from "config";

// import { API_RESOURCES } from "config";
import { IArea, IDevice, IDeviceConnectState } from "interfaces";
import { useMemo } from "react";
import ResetDevice from "./private/ResetDevice";
import DeviceSync from "./private/DeviceSync";
import SetDeviceMode from "./private/SetDeviceMode";
import DeviceSyncData from "./private/DeviceDataSync";

const { SearchOutlined, PlusOutlined } = Icons;

export const DeviceList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<
    IDevice,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchDevice } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IDevice>();

  const {
    selectProps: { showSearch, onSearch, ...restSelectAreaProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const areaOptions = useMemo(
    () => restSelectAreaProps.options,
    [restSelectAreaProps]
  );

  const connectionStateOptions = useMemo(
    () => DEVICE_CONNECTION_STATE_LIST(t),
    [t]
  );

  const deviceTypeOptions = useMemo(() => DEVICE_TYPE_LIST(t), [t]);

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDevice()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("devices.fields.name")}
        />

        <Table.Column
          dataIndex="code"
          key="code"
          title={t("devices.fields.code")}
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title={t("devices.fields.type")}
          render={(type) => (
            <TextField
              value={
                deviceTypeOptions.find(
                  (deviceType) => deviceType.value === type
                )?.label
              }
            />
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("devices.fields.type")}
                options={deviceTypeOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="area"
          key="areas"
          title={t("devices.fields.area")}
          render={(area) => <TextField value={area?.name} />}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="multiple"
                style={{ minWidth: 250 }}
                placeholder={t("devices.fields.area")}
                options={areaOptions}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column<IDevice>
          dataIndex="connectionState"
          key="connectionState"
          title={t("devices.fields.status")}
          render={(connectionState: IDeviceConnectState) =>
            connectionState === "CONNECTED" ? (
              <Tag color="success">{t("devices.fields.status_true")}</Tag>
            ) : (
              <Tag color="error">{t("devices.fields.status_false")}</Tag>
            )
          }
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("devices.fields.status")}
                options={connectionStateOptions}
              />
            </FilterDropdown>
          )}
        />

        {/* <Table.Column<IDevice>
          dataIndex="device-status"
          key="device-status"
          title={t("devices.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.devices,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("devices.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("devices.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        <Table.Column<IDevice>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("devices.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("devices.notification.deleteSuccess"),
                }}
              />
              <ResetDevice data={record} />
              <DeviceSync data={record} />
              <SetDeviceMode data={record} />
              <DeviceSyncData data={record} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
